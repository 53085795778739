import React, { useState, useEffect } from 'react';
import { validateEmail } from "../../utils";
import t from '../../translate';
import Universities from "./universities.js";
import NotificationBox from "../../alert/notification";
import apiCall from "../../api";

const Step3 = ({ registrationTexts, changeLanguage, registrationData, setRegistrationData }) => {

  const texts = {
    title: {
      1: "Leuk dat je dit programma wilt volgen!",
      2: "Great to see you want to do this program!",
    },
    intro: {
      1: "We doen ook onderzoek naar de effectiviteit en tevredenheid met onze programma's. Hieronder staat meer informatie over het onderzoek.",
      2: "We also research effectiveness and satisfaction with our programs. Below is more information about our research.",
    }
  }

  const iSleepTexts = {
    intro: {
      1: `We doen ook onderzoek naar de effectiviteit en tevredenheid met dit programma. Hieronder staat meer informatie over het onderzoek.<br />
      <br />
      <strong>Hoelang duurt het onderzoek?</strong><br />
      Ongeveer 4.5 maand, waarvan de eerste 5 weken intensiever zijn. <br />
      <br />
      <strong>Wat houdt deelname in?</strong><br />
      Je vult eerst een aantal vragenlijsten in. Als je geschikt bent om mee te doen wordt je per toeval ingedeeld in één van twee groepen:<br />
      <ul>
        <li>Groep 1 -> i-Sleep & BioClock programma (5 weken online zelfhulpprogramma met coaching)</li>
        <li>Groep 2 -> Psycho-educatie voor slapeloosheid (5 weken online zelfhulpprogramma)</li>
      </ul>
      <ul>
        <li>Je <strong>volgt het programma</strong> om je slaap te verbeteren.</li>
        <li>Je vult <strong>vier vragenlijsten (van circa 10-20 minuten)</strong> in over je slaapproblemen en mentale gezondheid (aan het begin van het programma, halverwege het programma in week 3, aan het eind van het programma in week 6, en na 4.5 maand).</li>
        <li>Je houdt in week 1 en week 6 dagelijks <strong>een slaapdagboek</strong> bij. Ook tijdens het programma raden we aan om het slaapdagboek dagelijks bij te houden, want het gaat je helpen om meer inzicht in je slaapgedrag te krijgen.</li>
      </ul>
      <br />
      <strong>Let op:</strong> Binnen het i-Sleep & BioClock programma gebruiken we een methode om jouw slaap te ‘resetten’, waardoor je weer beter gaat doorslapen. Je gaat je met deze methode jouw slaappatroon veranderen. Daardoor kan het zijn dat je je een tijd vermoeider en slaperiger voelt. De bedoeling is dat je na een tijdje beter doorslaapt, waardoor je je dan juist weer fitter voelt. Heb je momenteel tentamens? Dan kan het handig zijn om de deelname aan het programma na de tentamenperiode in te plannen. Bij vragen kun je contact opnemen met <a href="mailto:isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a><br />
      <br />
      Voor meer informatie lees de informatiebrief hieronder goed door.
      `,
      2: `We are currently conducting research into the effectiveness and satisfaction of this program. Below is more information about the research.<br />
      <br />
      <strong>How long does the study take?</strong><br />
      About 4.5 months, of which the first 5 weeks are more intensive.<br />
      <br />
      <strong>What does participation in this study entail?</strong><br />
      You first complete a couple of questionnaires. If you are suitable to participate, you will be randomly assigned to one of two groups:<br />
      <ul>
        <li>Group 1 -> i-Sleep & BioClock program (5 weeks online self-help program with coaching)</li>
        <li>Group 2 -> Psychoeducation for insomnia (5 weeks online self-help program)</li>
      </ul>
      <ul>
        <li>You <strong>follow the assigned program</strong> to improve your sleep.</li>
        <li>You <strong>complete four questionnaires (of approximately 10-20 minutes)</strong> about your sleep and mental health (at the beginning of the program, halfway through the program in week 3, at the end of the program in week 6, and after 4.5 months).</li>
        <li>You keep a daily <strong>sleep diary</strong> in week 1 and week 6. We also recommend keeping the sleep diary daily during the program, because it will help you gain more insight into your sleeping behavior.</li>
      </ul>
      <strong>Please note:</strong> Within the i-Sleep & BioClock program we use a method to 'reset' your sleep, so that you can sleep better again. You will change your sleeping pattern with this method. As a result, you may initially feel more tired and sleepy for a short time. After a while, you will adjust to your new pattern and will sleep better, which will then make you feel fitter again. Do you currently have exams? Then it may be useful to plan participation in the program after the exam period. If you have any questions, please contact <a href="mailto:isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a><br />
      <br />
      For more information, read the information letter below carefully.
      `
    }
  }

  const alternativeInformedConscent = {
    45: {
      1: `<h3>Informatie voor deelname aan onderzoek</h3><br />
      <br />
      <strong>MoodLift LifeHack</strong><br />
      <br />
      Beste student,<br />
      <br />
      Wij vragen je vriendelijk om mee te doen aan een wetenschappelijk onderzoek. Meedoen is vrijwillig. Om mee te doen is wel jouw digitale toestemming nodig. <br /> 
      <br />
      Dit onderzoek wordt uitgevoerd door het Caring Universities consortium bestaande uit de Vrije Universiteit Amsterdam, de Universiteit van Leiden, de Universiteit van Maastricht, de Universiteit van Utrecht, Erasmus Universiteit, Universiteit van Amsterdam, Hogeschool InHolland, Avans Hogeschool en Hogeschool Rotterdam. De VU treedt op als verwerkingsverantwoordelijke voor deze gegevensverwerking. Vragen, opmerkingen en klachten over de manier waarop jouw gegevens worden verwerkt, kunnen worden gericht aan de verantwoordelijke VU-onderzoekers en aan de Functionaris Gegevensbescherming van de VU, of de Autoriteit Persoonsgegevens, zoals aangegeven in onderdeel 5 van deze informatiebrief. Voordat je beslist of je wilt meedoen aan dit onderzoek, krijgt je uitleg over wat het onderzoek inhoudt. Lees deze informatie rustig door en vraag de onderzoeker uitleg als je vragen hebt. Je kunt er ook over praten met jouw partner, vrienden of familie. <br />
      <br />
      <strong>1. Doel van het onderzoek </strong><br />
      Middels dit onderzoek willen we achterhalen of LifeHack, een begeleid online zelfhulpprogramma om levensvaardigheden aan te leren, effectief is in het verbeteren van mentaal welzijn. Daarnaast willen we onderzoeken of studenten tevreden zijn over dit programma en wat hun mening is over de toepasbaarheid van dit programma met verschillende korte vragenlijsten die worden aangeboden na afloop van het programma. Tevens willen we onderzoeken of het programma invloed heeft op psychische klachten, zoals depressie, angst of stress en de kwaliteit van leven van studenten. Let goed op: LifeHack is geen (vervanging van) therapie. Als je last hebt van psychische klachten raden we aan om (ook) andere vormen van hulp te zoeken. Wanneer je hier hulp bij zou willen, neem dan contact op met het onderzoeksteam, dan denken we actief mee over wat een geschikte vorm van ondersteuning zou kunnen zijn. <br />
      <br />
      <strong>2. Wat meedoen inhoudt  </strong><br />
      Deze studie is toegankelijk voor studenten die momenteel een minder dan optimaal mentaal welzijn ervaren wat wordt gemeten met een korte vragenlijst. Na besluit om deel te nemen aan de studie zal je worden toegewezen aan groep A of groep B als uit een korte vragenlijst blijkt dat je baat zou kunnen hebben bij LifeHack. De kans om in een groep ingedeeld te worden is 50-50 en wordt automatisch en willekeurig (middels randomisatie) gedaan. Groep A zal direct met het programma beginnen, groep B begint na 4 weken.<br />
      <br />
      We verzoeken je om vragenlijsten in te vullen op drie meetmomenten (Group A) of op vier meetmomenten (Groep B): aan het begin van de studie, 4 weken na de start van de studie, 8 weken na de start van de studie (alleen Groep B) en 6 maanden na het begin van de studie. Het kost ongeveer 10 minuten om de vragenlijsten in te vullen en de vragen gaan over je mentale welzijn. Je kunt gebeld worden door iemand van het onderzoeksteam aan het begin en einde van het onderzoek over het invullen van de vragenlijsten. Deelname betekent verder dat je actief werkt aan het zelfhulpprogramma. LifeHack bevat 13 verschillende modules waarbij je zelf kiest welke en hoeveel modules je wilt volgen. Een module duurt tussen de 20 en 40 minuten. We adviseren je om aan 1 module per week te werken. <br />
      <br />
      Het programma is gebaseerd op wetenschappelijk onderzoek, ontworpen door experts op het gebied van online zelfhulpprogramma's in samenwerking met experts op het gebied van de klinische psychologie. Tevens wordt er bij de programma's begeleiding aangeboden door e-coaches. Deze coaches zijn getrainde klinische psychologie masterstudenten welke worden gesuperviseerd door klinisch psychologen. De e-coaches zullen begeleiding bieden door feedback te geven op alle opdrachten die je doet tijdens het programma. Al het contact is digitaal en anoniem als je dat wilt. <br /> 
      <br />
      <strong>3. Mogelijke voor- en nadelen </strong><br />
      Een mogelijk voordeel van het meedoen aan dit onderzoek is dat LifeHack 24 uur per dag beschikbaar is. Je kunt eraan werken in je eigen tempo. Een gevolg van het werken met LifeHack is dat mogelijk je mentale welzijn verbetert en dat jouw kwaliteit van leven omhoog zal gaan. Daarnaast verwachten we dat jouw deelname bij zal dragen aan kennis over het programma en zullen we meer te weten komen over de praktische voordelen van het programma. We verwachten niet dat het meedoen aan dit onderzoek enige risico's met zich meebrengt. Echter, het is in principe mogelijk dat je ongemak ervaart tijdens het invullen van de vragenlijsten omdat sommige vragen confronterend kunnen zijn. Daarnaast kost deelname aan dit onderzoek enige tijd en moeite. <br /> 
      <br />
      <strong>4. Als je niet wilt meedoen of wilt stoppen met het onderzoek </strong><br />
      Je beslist zelf of je meedoet aan het onderzoek. Deelname is vrijwillig. Doe je mee aan het onderzoek? Dan kan je je altijd bedenken. Je mag tijdens het onderzoek stoppen. Je hoeft niet te zeggen waarom je stopt. Wel moet je dit direct melden aan de onderzoeker. De gegevens die tot dat moment zijn verzameld, worden gebruikt voor het onderzoek. <br />
      <br />
      <strong>5. Gebruik en bewaren van jouw gegevens </strong><br />
      Om dit onderzoek te kunnen uitvoeren, moeten we jouw persoonsgegevens verzamelen en verwerken. Wij bewaren identificerende gegevens zoals naam en contactgegevens apart van alle andere gegevens die we over jou verzamelen. Elke deelnemer krijgt een ID-code toegewezen waaraan de verzamelde gegevens worden gekoppeld. Deze ID-code wordt afzonderlijk bewaard en is slechts toegankelijk voor de onderzoeker indien dit noodzakelijk is voor de koppeling van gegevens ten behoeve van contact of toestemming. De 'gecodeerde' gegevens zullen binnen het onderzoek worden geanalyseerd. <br />
      <br />
      Alle gegevens worden opgeslagen en verwerkt conform alle regels en richtlijnen van de AVG op een goed beveiligde manier. <br /> 
      <br />
      Indien de onderzoekers de gegevens op een later moment willen benutten ten behoeve van vervolgonderzoek en/of wetenschappelijk onderwijs, of als tussentijdse interventies vereisen dat nieuwe gegevens worden verwerkt of in andere manieren gebruikt dan waarvoor ze oorspronkelijk verzameld zijn, dan wordt dit aan de deelnemers gecommuniceerd en wordt hiervoor, zo vaak als redelijkerwijs mogelijk, opnieuw toestemming gevraagd. <br />
      <br />
      <strong>Uitwisseling van gegevens binnen MoodLift </strong><br />
      Voor het onderzoek worden jouw onderzoeksgegevens gecodeerd gedeeld met de Vrije Universiteit Amsterdam. De onderzoeksgegevens zijn bij publicatie niet te herleiden naar jou. Als je de toestemmingsverklaring ondertekent, geef je toestemming voor het verzamelen, bewaren en inzien van jouw persoonsgegevens. De onderzoeker bewaart jouw gegevens 15 jaar. Daarna worden de persoonsgegevens vernietigd.  <br />
      <br />
      <strong>Ipportunities B.V. </strong><br />
      De gegevens worden tevens met dezelfde (beveiligings-)maatregelen opgeslagen op de servers van VIP Internet door Ipportunities B.V. Met deze instelling heeft MoodLift een overeenkomst. Zij beheren het platform waarop de interventies gebruikt kunnen worden.  <br />
      <br />
      <strong>FAIR science </strong><br />
      Het streven is om de data van het MoodLift project Findable, Accessible, Interoperable en Reusable (FAIR) te maken. Hierbij zullen schriftelijke verzoeken voor het delen van data met erkende instellingen met een specifieke vraagstelling in overweging worden genomen. Indien over wordt gegaan op het delen van data zal dit enkel worden gedaan met meerdere waarborgen zoals pseudonymisering, versleuteling en beveiligingsmaatregelen.  <br />
      <br />
      <strong>Meer informatie over jouw rechten bij verwerking van gegevens </strong><br />
      Je kan de onderzoeker vragen om een elektronische kopie van gegevens die je hebt aangeleverd of die direct bij jou gemeten zijn. Indien je ontevreden bent over hoe wordt omgegaan met jouw privacy dan kan je een klacht indienen bij de Functionaris Gegevensbescherming van de VU via <a href="mailto:functionarisgegevensbescherming@vu.nl">functionarisgegevensbescherming@vu.nl</a>. Ook kan je zelf terecht bij de Functionaris Gegevensbescherming van jouw universiteit en de Autoriteit Persoonsgegevens via <a href="https://autoriteitpersoonsgegevens.nl" target="_blank">https://autoriteitpersoonsgegevens.nl</a>. 
      <br />
      <strong>6. Vergoeding voor meedoen </strong><br />
      Voor het meedoen aan dit onderzoek krijg je een VVV-bon ter waarde van 10 euro. Deze wordt naar je opgestuurd als je alle vragenlijsten volledig in hebt gevuld. <br />
      <br />
      <strong>7. Heb je vragen? </strong><br />
      Bij vragen kan je contact opnemen met het onderzoeksteam. <br />
      <br />
      <strong>Contactgegevens</strong><br />
      Het MoodLift Onderzoeksteam <br />
      t.a.v. dr. S. van Luenen <br />
      Universiteit Leiden, FSW, afdeling klinische psychologie <br />
      Wassenaarseweg 52, kamer 2B30 <br />
      <a href="mailto:caring.universities@leidenuniv.nl">caring.universities@leidenuniv.nl</a> <br />
      <br />
      <ul>
        <li>Ik heb de informatiebrief gelezen. Ook kon ik vragen stellen. Mijn vragen zijn voldoende beantwoord. Ik had genoeg tijd om te beslissen of ik meedoe. </li>
        <li>Ik weet dat meedoen vrijwillig is. Ook weet ik dat ik op ieder moment kan beslissen om toch niet mee te doen of te stoppen met het onderzoek. Daarvoor hoef ik geen reden te geven. </li>
        <li>Ik geef toestemming voor het verzamelen en gebruiken van mijn gegevens op de manier en voor de doelen die in de informatiebrief staan. </li>
        <li>Ik geef toestemming om mijn gegevens nog 15 jaar na dit onderzoek te bewaren. </li>
        <li>Ik wil meedoen aan dit onderzoek. </li>
      </ul>
      `,
      2: `<h3>Information for participation in research</h3><br />
      <br />
      <strong>MoodLift LifeHack </strong><br />
      <br />
      Dear student, <br />
      <br />
      We kindly ask you to participate in a scientific study. Participation is voluntary. Your digital permission is required in order to participate.  <br />
      <br />
      This research is conducted by the Caring Universities consortium which includes the Vrije Universiteit Amsterdam, Leiden University, Maastricht University, Utrecht University, University of Amsterdam, Avans university of Applied Sciences, InHolland University of Applied Sciences, Rotterdam University of Applied Sciences and the Erasmus University. The VU is end-responsible for the data processing. Questions, comments or complaints concerning the way your data is handled can be addressed to the responsible VU researchers, Data Protection Officer of the VU, or the Data Protection Authority, as is described in section 5 of this information letter. Before you decide whether you want to participate in this study, you will be given an explanation about what the study involves. Please read this information carefully and ask the research team for an explanation if you have any questions. You may also discuss it with your partner, friends or family. <br />
      <br />
      <strong>1. Purpose of the study </strong><br />
      Through this study, we want to investigate whether LifeHack, a guided online self-help program to learn life skills, is effective in improving mental wellbeing. We also aim to investigate your opinion about this programme concerning your satisfaction level and usability of this programme. In addition, we want to study whether the programme affects psychological complaints, such as depression, anxiety or stress, and the quality of life of students. Please note: LifeHack is not a (replacement of) therapy. If you suffer from psychological complaints, we recommend that you (also) seek other forms of help. If you would like help with this, please get in touch with the research team and we will actively consider what a suitable form of support could be.<br /> 
      <br />
      <strong>2. What participation involves </strong><br />
      This study is accessible for students currently experiencing less than optimal mental wellbeing which is assessed with a short questionnaire. After deciding to participate in the study, you will be assigned to group A or group B if a short questionnaire shows you could benefit from LifeHack. You will be randomly allocated to group A or group B with a 50% chance of entering either group. Group A will start with the programme immediately, group B will start with the programme after 4 weeks. <br /> 
      <br />
      We kindly ask you to complete questionnaires at three (group A) or four (Group B) time points: directly at the start of the study, 4 weeks after the start, 8 weeks after the start (only group B) and 6 months after starting. These questionnaires will take approximately 10 minutes to complete, and they contain questions about mental wellbeing. You may receive a call from the research team at the beginning and end of the study about completing the questionnaires. Participation further entails that you follow the self-help programme. LifeHack contains 12 different modules where you choose which and how many modules you want to follow. A module takes between 20 and 40 minutes. We advise you to work on one module per week.<br />
      <br />
      Techniques used in LifeHack are evidence-based, created by experts in the field of internet-based self-help programmes and in collaboration with experts in the field of clinical psychology. They also include guidance by e-coaches. These e-coaches are master students Clinical Psychology. They are extensively trained for e-coaching by licensed psychologists and guide you by giving feedback on the assignments you complete in the programme. All contact is digital and anonymous if you wish, and all e-coaches operate under supervision of a licensed psychologist. <br />
      <br />
      <strong>3. Possible advantages and disadvantages </strong><br />
      The advantages are that LifeHack is available 24 hours per day, and you can work on it at your own pace. As a result of working on this programme,  your mental wellbeing and your quality of life may improve. Moreover, your participation may contribute to increased knowledge about the programme and help us in determining its effectiveness. We expect that participation in this study does not entail any risks. However, you may experience some stress when filling out the questionnaires during assessments. In addition, participating costs some time and effort. <br />
      <br />
      <strong>4. If you do not want to participate or want to stop participating in 
      the study </strong><br />
      Participation is completely voluntary and only with your digital permission. You are free to stop the survey at any time and do not need to give a reason. However, you do need to notify the researcher if you decide to stop. The data collected until that time will still be used for the study. <br />
      <br />
      <strong>5. Usage and storage of your data </strong><br />
      To carry out this research, we must collect and process your personal data. We store identifying information such as name and contact information separately from all other information we collect about you. Each research participant is assigned an ID code to which the collected data is linked. This ID code is stored separately and is only accessible to the researcher if this is necessary for linking data for contact or permission. The "coded" data will be analyzed within the study. <br />
      <br />
      All data is stored and processed in accordance with all rules and guidelines of the GDPR in a well-secured manner. <br />
      <br />
      If the researchers want to use the data later for further research and/or scientific education, or if interim interventions require new data to be processed or used in other ways than for which they were originally collected, this will be communicated to the participants and permission is asked again, as often as reasonably possible. <br />
      <br />
      <strong>Exchange of data within MoodLift</strong><br />
      Your research data will be coded and shared with the VU University Amsterdam. The research data cannot be traced back to you upon publication. When you sign the declaration of consent, you consent to the collection, storage and viewing of your personal data. The researcher stores your data for 15 years. After this period of time, the personal data will be destroyed. <br />
      <br />
      <strong>Ipportunities B.V. </strong><br />
      The data is also stored by Ipportunities B.V. on the servers of VIP internet with the same (security) measures. MoodLift has an agreement with Ipportunities B.V. They manage the platform on which the interventions can be used. <br />
      <br />
      <strong>FAIR science </strong><br />
      The aim is to make the data of the MoodLift project Findable, Accessible, Interoperable and Reusable (FAIR). Written requests for data sharing from recognized institutions with specific questions will be considered. If we agree to share data, this will only be done with multiple safeguards such as pseudonymisation, encryption and security measures. <br />
      <br />
      <strong>More information about your rights when processing data</strong><br />
      You can ask the researcher for an electronic copy of data that you have supplied or that have been measured directly with you. If you are dissatisfied with how your privacy is handled, you can file a complaint with the Data Protection Officer via <a href="mailto:functionarisgegevensbescherming@vu.nl">functionarisgegevensbescherming@vu.nl</a>. You can also contact the Data Protection Officer of your university and the Dutch Data Protection Authority via <a href="" target="_blank">https://autoriteitpersoonsgegevens.nl</a>. <br />
      <br />
      <strong>6. Compensation for participation </strong><br />
      For participating in this study, you will receive a VVV voucher worth 10 euros. This will be sent to you once you have completed all the questionnaires. <br />
      <br />
      <strong>7. Any questions?  </strong><br />
      If you have any questions, please contact the study team.  <br />
      <br />
      <strong>Contact information</strong><br />
      The MoodLift Research Team <br />
      On behalf of dr. S. van Luenen <br />
      Leiden University, FSW, department of clinical psychology <br />
      Wassenaarseweg 52, room 2B30 <br />
      <a href="mailto:caring.universities@leidenuniv.nl">caring.universities@leidenuniv.nl</a> <br />
      <br />
      <ul>
        <li>I have read the information letter. I was also able to ask questions. My questions have been answered to my satisfaction. I had enough time to decide whether to participate.</li>
        <li>I know participation is voluntary. I know that I may decide at any time not to participate after all or to withdraw from the study. I do not need to give a reason for this. </li>
        <li>I consent to my data being used in the way and for the purpose stated in the information letter.</li>
        <li>I consent to my data being stored at the research location for another 15 years after this study.</li>
        <li>I want to participate in this study.</li>
      </ul>
      `
    },
    47: {
      1: `<h3>Proefpersoneninformatie voor deelname aan medisch-wetenschappelijk onderzoek</h3>
      <br />
      <strong>i-Sleep & BioClock voor studenten</strong><br />
      <i>Onderzoek naar de effectiviteit van een begeleide e-health slaap- en biologische klok
      interventie bij studenten (i-Sleep & BioClock)</i><br />
      <br />
      <strong>Inleiding</strong><br />
      Beste (PhD) student,<br />
      <br />
      Met deze informatiebrief willen we je vragen of je wilt meedoen aan medischwetenschappelijk onderzoek. Meedoen is vrijwillig. Je krijgt deze informatiebrief omdat je
      slaapproblemen hebt. Je leest hier om wat voor onderzoek het gaat, wat het voor je betekent,
      en wat de voordelen en nadelen zijn. Het is veel informatie. Lees de informatie goed door om
      te beslissen of je mee wilt doen. Als je mee wilt doen teken je het online
      toestemmingsformulier (bijlage C).<br />
      <br />
      <strong>Stel je vragen</strong><br />
      Je kunt jouw beslissing nemen met de informatie die je in deze informatiebrief vindt. Daarnaast raden we je aan om dit te doen:<br />
      <ul>
      <li>Stel vragen aan de onderzoeker die je deze informatie geeft. Je kunt je vragen mailen naar
      aan de onderzoeker naar <a href="isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a></li>
      <li>Praat met je partner, familie of vrienden over dit onderzoek.</li>
      <li>Lees de informatie op <a href="https://www.rijksoverheid.nl/mensenonderzoek" target="_blank">www.rijksoverheid.nl/mensenonderzoek</a>.</li>
      </ul>
      <strong>1. Algemene informatie</strong><br />
      De Universiteit Leiden (Afdeling Klinische Psychologie) heeft dit onderzoek opgezet. Hieronder noemen we Universiteit Leiden steeds de 'opdrachtgever'. Onderzoekers, dit kunnen ook klinisch psychologen zijn, voeren het onderzoek uit in verschillende universiteiten. De onafhankelijke deskundige van dit onderzoek is dr. Julia van den Berg (Universiteit Leiden).<br />
      <br />
      Deelnemers aan een medisch-wetenschappelijk onderzoek worden vaak proefpersonen genoemd. Zowel patiënten als mensen die gezond zijn, kunnen proefpersoon zijn.<br />
      <br />
      Voor dit onderzoek zijn 192 studenten van verschillende universiteiten en hogescholen nodig. De medisch-ethische toetsingscommissie METC Leiden Den Haag Delft heeft dit onderzoek goedgekeurd. <br />
      <br />
      <strong>2. Wat is het doel van het onderzoek?</strong><br />
      In dit onderzoek bekijken we hoe goed i-Sleep & BioClock werkt voor het verminderen van slaapproblemen bij studenten. i-Sleep & BioClock is een zelfhulpprogramma gebaseerd op de principes van Cognitieve Gedragstherapie (CGT). CGT is een veel gebruikte behandeling van mentale problemen, waarbij wordt geleerd om je beter te voelen door anders te leren denken en anders te leren doen.<br />
      We vergelijken de werking van i-Sleep & BioClock met de werking van online psychoeducatie. Psycho-educatie is een ander woord voor voorlichting. Online psycho-educatie is de meest gangbare manier om te leren met slaapklachten om te gaan.<br />
      <br />
      <strong>3. Wat is de achtergrond van het onderzoek?</strong><br />
      Veel studenten hebben last van slaapproblemen. Slaapproblemen kunnen veel verschillende aspecten van het leven beïnvloeden, zoals bijvoorbeeld stemming, stress, prestaties en kwaliteit van leven. Bovendien worden langdurige slaapproblemen in verband gebracht met verschillende psychische stoornissen, zoals depressie- en angststoornissen. We hebben een begeleid online zelfhulpprogramma voor studenten ontwikkeld dat is gebaseerd op principes van de cognitieve gedragstherapie voor slapeloosheid. Het richt zich op het begrijpen en aanpakken van problematische gedachten en gedrag die bijdragen aan emotionele problemen en slapeloosheid. Door deze te herkennen en aan te passen, kun je je beter gaan voelen en beter omgaan met situaties die eerder moeilijk waren. Het programma duurt in totaal ongeveer 5 weken. De onderwerpen die hierin aan bod komen zijn de biologische klok, het veranderen van gedrag dat slaap belemmert (slaaphygiëne), veranderen van het slaappatroon, ontspanning, en omgaan met piekeren en negatieve gedachten over slaap.<br />
      <br />
      <strong>4. Hoe verloopt het onderzoek?</strong><br />
      <i>Hoelang duurt het onderzoek?</i><br />
      Doe je mee met het onderzoek? Dan duurt dat in totaal ongeveer 4,5 maand, waarvan de eerste 5 weken intensiever zijn.<br />
      <br />
      <i>Stap 1: Ben je geschikt om mee te doen?</i><br />
      We willen eerst weten of je geschikt bent om mee te doen. Daarom nemen we eerst een aantal vragenlijsten af. Je beantwoordt de vragenlijsten online via het MoodLift platform. Als blijkt dat je niet geschikt bent om mee te doen ontvang je een e-mail met nadere uitleg. Mocht je wel geschikt zijn om mee te doen kan je starten met het aan jou toegewezen online programma (zie stap 2).<br />
      <br />
      <i>Stap 2: De programma's</i><br />
      Je gaat 5-6 weken lang een online zelfhulpprogramma volgen. Het programma staat op een online platform. Je kunt inloggen via je laptop, tablet of telefoon en hebt hiervoor geen aanvullende software nodig.<br />
      <br />
      Voor dit onderzoek maken we 2 groepen:<br />
      <ul>
      <li>Groep 1. Studenten in deze groep krijgen het online i-Sleep & BioClock programma (een zelfhulpprogramma gebaseerd op de cognitieve gedragstherapie voor slapeloosheid).</li>
      <li>Groep 2. Studenten in deze groep krijgen online psycho-educatie (voorlichting) voor slapeloosheid.</li>
      </ul>
      Loting bepaalt welke of je in groep 1 of groep 2 zit. De kans om gerandomiseerd (per toeval ingedeeld) te worden in een van beide groepen is 50/50.<br />
      <br />
      <i>Stap 3: Onderzoeken en metingen</i><br />
      Je hebt met jouw studiemailadres een account aangemaakt voor het MoodLift platform. Bij de aanmelding mag je zelf je persoonlijke coach kiezen. We raden je aan om een coach te kiezen die je niet persoonlijk kent. Je kan ervoor kiezen om je anoniem aan te melden. Dat betekent dat de coach jouw naam en persoonlijke gegevens zoals je mailadres niet kan inzien. Voordat het programma begint ga je online vragenlijsten invullen. Het invullen van een vragenlijst duurt ongeveer 15-20 minuten. We verzoeken je om vragenlijsten in te vullen op vier meetmomenten: aan het begin van het programma, halverwege het programma (3 weken na start een zeer korte vragenlijst), aan het einde van het programma (6 weken na start), en nog een keer 18 weken na start. Enkele dagen na de aanmelding wordt je gebeld voor een intakegesprek met de coach, of als je anoniem bent aangemeld door de onderzoeker. Gedurende het onderzoek zal je eventueel via de mail en/of telefonisch herinnerd worden aan het invullen van de vragenlijsten. Ook houd je in de eerste week een slaapdagboek bij, waarvoor je op het platform gaat inloggen. Dit duurt ongeveer 3 minuten en kan ingevuld worden op jouw smartphone. Dit slaapdagboek maakt ook deel uit van het programma zelf. Na 6 weken (aan het einde van het programma) vragen we je opnieuw om het slaapdagboek bij te houden. Zo krijg je inzicht in waar je nu staat en hoe jouw slaap is veranderd. Deelname betekent verder dat je actief werkt aan het zelfhulpprogramma. Elk programma bevat meerdere modules waaraan je tussen de 30 en 60 minuten werkt. We adviseren je om aan 1 tot maximaal 2 modules per week te werken. Als je in groep 1 zit
      ontvang je bovendien tekstueel feedback op de antwoorden in de modules door jouw ecoach. Als je in groep 2 zit ontvang je geen coaching.<br />
      Zie bijlage B voor een schema over de meet-momenten.<br />
      <br />
      <strong>5. Welke afspraken maken we met je?</strong><br />
      We willen graag dat het onderzoek goed verloopt. Daarom maken we de volgende afspraken met jou:<br />
      <ul>
      <li>Je neemt actief deel aan het programma.</li>
      <li>Je vult de vragenlijsten en het slaapdagboek in.</li>
      <li>Je neemt contact op met de coach of onderzoeker in deze situaties:<ul>
      <li>Je wordt in een ziekenhuis opgenomen of behandeld.</li>
      <li>Je krijgt plotseling problemen met je gezondheid.</li>
      <li>Je wilt niet meer meedoen met het onderzoek.</li>
      <li>Je telefoonnummer, adres of e-mailadres verandert.</li>
      </ul></li>
      </ul>
      <strong>6. Van welke bijwerkingen, nadelige effecten of ongemakken kun je last krijgen?</strong><br />
      De programma's en de procedures die in dit onderzoek worden gebruikt, zijn veilig en brengen geen risico's met zich mee. Alle procedures die tijdens dit onderzoek worden uitgevoerd, zijn veilig en eenvoudig, aangezien ze eerder in verschillende populaties zijn gebruikt. Echter, het is in principe mogelijk dat je ongemak ervaart tijdens het invullen van de vragenlijsten. Daarnaast kost deelname aan dit onderzoek enige tijd en moeite. Er worden geen nadelige bijwerkingen verwacht. Mocht je tijdens het onderzoek toch nadelige effecten ervaren, laat het dan weten aan de coach of neem contact op met de onderzoekers of de   medisch verantwoordelijke (S. Struijs, voor contactgegevens zie Bijlage A). Voor crisis situaties (zoals het ontstaan van gedachtes van zelfbeschadiging of zelfdoding) hebben wij een aanvullend protocol.<br />
      <br />
      <i>Twijfel je nog of je mee wilt doen?</i><br />
      Je beslist zelf of je meedoet aan het onderzoek. Deelname is vrijwillig. Als je beslist om wel mee te doen aan het onderzoek dan kun je je altijd bedenken. Je mag tijdens het onderzoek stoppen. Je hoeft niet te zeggen waarom je stopt. Wel moet je dit direct melden aan de onderzoeker. De gegevens die tot dat moment zijn verzameld, worden gebruikt voor het onderzoek.<br />
      <br />
      <strong>7. Wat zijn de voordelen en de nadelen als je meedoet aan het
      onderzoek?</strong><br />
      Meedoen aan het onderzoek kan voordelen en nadelen hebben. Hieronder zetten we ze op een rij. Denk hier goed over na, en praat erover met anderen.<br />
      Een mogelijk voordeel van het meedoen aan dit onderzoek is dat de zelfhulpprogramma's 24 uur per dag beschikbaar zijn. Je kunt eraan werken in je eigen tempo. Een gevolg van het werken met deze programma's  is dat jouw symptomen mogelijk verbeteren en dat jouw kwaliteit van leven omhoog zal gaan. Het zelfhulpprogramma is geen therapie, maar een zelfhulpprogramma waarmee je zelf meer vat op je slaapproblemen kan krijgen. Met jouw deelname help je de onderzoekers om meer inzicht te krijgen in het verhelpen van slapeloosheid bij studenten. We verwachten niet dat het meedoen aan dit onderzoek enige
      risico's met zich meebrengt. <br />
      <br />
      Meedoen aan het onderzoek kan deze nadelen hebben:<br />
      <ul>
      <li>Meedoen aan het onderzoek kost je extra tijd.</li>
      <li>Je moet je houden aan de afspraken die horen bij het onderzoek.</li>
      </ul>
      <i>Wil je niet meedoen?</i><br />
      Je beslist zelf of je meedoet aan het onderzoek. Als je beslist om niet mee te doen aan het onderzoek maar wel hulp voor jouw slaapklachten wil krijgen kun je contact opnemen met een arts of psycholoog om jouw klachten te bespreken en te behandelen.<br />
      <br />
      <strong>8. Wanneer stopt het onderzoek?</strong><br />
      De onderzoeker laat het je weten als er nieuwe informatie over het onderzoek komt die belangrijk voor je is. De onderzoeker vraagt je daarna of je blijft meedoen.<br />
      <br />
      In deze situaties stopt voor je het onderzoek:<br />
      <ul>
      <li>Alle onderzoeken volgens het schema zijn voorbij.</li>
      <li>Je wil zelf stoppen met het onderzoek. Dat mag op ieder moment. Meld dit dan meteen bij de onderzoeker via het e-mail adres isleep-bioclock@fsw.leidenuniv.nl. Je hoeft er niet bij te vertellen waarom je stopt.  De onderzoeker kan je voor jouw veiligheid nog andere aanpreekpunten geven.</li>
      <li>De onderzoeker vindt het beter voor je om te stoppen. De onderzoeker zal je eventueel nog wel uitnodigen voor een nacontrole.</li>
      <li>Een van de volgende instanties besluit dat het onderzoek moet stoppen:<ul>
        <li>de Universiteit Leiden,</li>
        <li>de overheid, of</li>
        <li>de medisch-ethische commissie die het onderzoek beoordeelt.</li>
      </ul></li>
      </ul>
      <i>Wat gebeurt er als je stopt met het onderzoek?</i><br />
      De onderzoekers gebruiken de gegevens die tot het moment van stoppen zijn verzameld. Het hele onderzoek is afgelopen als alle deelnemers klaar zijn met hun programma en followup periode.<br />
      <br />
      <strong>9. Wat gebeurt er na het onderzoek?</strong><br />
      <i>Kun je het programma blijven gebruiken?</i><br />
      Als je in groep 1 zit kun je het i-Sleep & BioClock programma nog anderhalf jaar blijven gebruiken. Je hebt dus in totaal 2 jaar toegang tot het platform. De coaching periode stopt na maximaal 8 weken, wat betekent dat je dan geen feedback meer ontvangt van de coach, ook als je nog niet alle modules hebt afgerond. Zit je in groep 2 (psycho-educatie) dan kun je na afloop van het onderzoek (ongeveer 4,5 maanden) toegang te krijgen tot het i-Sleep & BioClock programma als je dan nog student bent bij de deelnemende universiteiten. Je ontvangt hiervoor een extra mail. Dit programma is vanaf dit moment 2 jaar voor je beschikbaar.<br />
      <br />
      <i>Krijg je de resultaten van het onderzoek?</i><br />
      Ongeveer 1 jaar nadat het onderzoek is afgerond laat de onderzoeker je weten wat de belangrijkste uitkomsten zijn van het onderzoek. Wil je dit niet weten? Zeg dat dan tegen de onderzoeker. Hij/zij zal het je dan niet vertellen.<br />
      <br />
      <strong>10.Wat doen we met jouw gegevens?</strong><br />
      Doe je mee met het onderzoek? Dan geef je ook toestemming om je gegevens te verzamelen, gebruiken en bewaren.<br />
      <br />
      <i>Welke gegevens bewaren we?</i><br />
      We bewaren deze gegevens:<br />
      <ul>
      <li>jouw naam</li>
      <li>jouw geslacht</li>
      <li>jouw e-mail adres</li>
      <li>jouw geboortedatum</li>
      <li>gegevens over jouw gezondheid</li>
      <li> die we tijdens het onderzoek verzamelen (bijv. slaapdagboek data, jouw antwoorden op de opdrachten in het programma, en loggegevens)</li>
      </ul>
      <i>Waarom verzamelen, gebruiken en bewaren we jouw gegevens?</i><br />
      We verzamelen, gebruiken en bewaren je gegevens om de vragen van dit onderzoek te kunnen beantwoorden. En om de resultaten te kunnen publiceren. Gegevens kunnen worden gebruikt door de opdrachtgever en bedrijven die de opdrachtgever helpen bij het analyseren van onderzoeksgegevens.<br />
      <br />
      <i>Hoe beschermen we jouw privacy?</i><br />
      Om jouw privacy te beschermen geven we jouw gegevens een code. Op al jouw gegevens zetten we alleen deze code. De sleutel van de code bewaren we op een beveiligde plek op een beveiligde schijf van de universiteit, die alleen toegankelijk is voor de onderzoekers. Als we jouw gegevens verwerken, gebruiken we steeds alleen die code. Ook in rapporten en publicaties over het onderzoek kan niemand terughalen dat het over jou ging.<br />
      <br />
      <i>Hoe zit het met de veiligheid van het MoodLift platform?</i><br />
      Je communiceert via het platform via een chat met jouw coach. De communicatie tussen de gebruikers van de MoodLift website en de webserver verloopt gecodeerd en kan onderweg niet afgevangen worden.<br />
      <br />
      <i>Wie kunnen jouw gegevens zien?</i><br />
      Sommige personen kunnen wel jouw naam en andere persoonlijke gegevens zonder code inzien. Dit kunnen gegevens zijn die speciaal voor dit onderzoek zijn verzameld.<br />
      <br />
      Dit zijn mensen die controleren of de onderzoekers het onderzoek goed en betrouwbaar uitvoeren. Deze personen kunnen bij jouw gegevens komen:
      <ul>
      <li>De onderzoekers dr. Niki Antypa (hoofdonderzoeker), dr. Sascha Struijs (coördinator MoodLift), en Laura Pape (PhD studente)</li>
      <li>De persoonlijke coach en superviserende psychologen hebben gedeeltelijke toegang tot de gegevens tenzij je anoniem aanmeldt. Dit gebeurt enkel volgens professionele standaarden m.b.t. anonimiteit en veiligheid zoals vastgesteld in de beroepscode van het Nederlands Instituut van Psychologen (<a href="https://www.psynip.nl/uwberoep/beroepsethiek/beroepscode/" target="_blank">www.psynip.nl/uwberoep/beroepsethiek/beroepscode</a>).</li>
      <li>Medewerkers van het bedrijf dat de MoodLift platform beheert hebben alleen toegang tot de gegevens wanneer het nodig is voor het onderhoud van het platform (bijv. voor het oplossen van bugs op het platform). Alle persoonsgegevens zullen strikt vertrouwelijk en conform de AVG worden behandeld. Dit is contractueel vastgelegd.</li>
      <li>Leden van de commissie die de veiligheid van het onderzoek in de gaten houdt.</li>
      <li>Een controleur die voor de Universiteit Leiden werkt.</li>
      <li>Nationale en internationale toezichthoudende autoriteiten.</li>
      </ul>
      Deze personen houden jouw gegevens geheim. Voor inzage door deze personen vragen we je toestemming te geven. De Inspectie Gezondheidszorg en Jeugd kan zonder jouw toestemming jouw gegevens inzien.<br />
      <br />
      <i>Hoelang bewaren we jouw gegevens?</i><br />
      We bewaren je gegevens 15 jaar op de Universiteit Leiden.<br />
      <br />
      <i>Mogen we jouw gegevens gebruiken voor ander onderzoek?</i><br />
      Je verzamelde gegevens kunnen ook van belang zijn voor ander wetenschappelijk onderzoek op het gebied van slapeloosheid bij studenten en van de verdere ontwikkeling van de behandeling ervan. Daarvoor zullen je gegevens 15 jaar worden bewaard op de universiteit. In het toestemmingsformulier geef je aan of je dit goed vindt. Geef je geen toestemming? Dan kunt je nog steeds meedoen met dit onderzoek. Je krijgt dezelfde zorg.<br />
      <br />
      <i>Kun je jouw toestemming voor het gebruik van jouw gegevens weer intrekken?</i><br />
      Je kunt jouw toestemming voor het gebruik van je gegevens op ieder moment intrekken. Zeg dat dan tegen de onderzoeker. Dit geldt voor het gebruik in dit onderzoek en voor het gebruik in ander onderzoek. Maar let op: trek je je toestemming in, en hebben onderzoekers dan al gegevens verzameld voor een onderzoek? Dan mogen zij deze gegevens nog wel gebruiken.<br />
      <br />
      <i>Wil je meer weten over je privacy?</i><br />
      <ul>
      <li>Wil je meer weten over jouw rechten bij de verwerking van persoonsgegevens? Kijk dan op <a href="https://www.autoriteitpersoonsgegevens.nl" target="_blank">www.autoriteitpersoonsgegevens.nl</a>.</li>
      <li>Heb je vragen over jouw rechten? Of heb je een klacht over de verwerking van je
      persoonsgegevens? Neem dan contact op met degene die verantwoordelijk is voor
      de verwerking van je persoonsgegevens. Voor jouw onderzoek is dat:<ul><li>Raymond van Erkel [Privacy officer, Universiteit Leiden]<br />
      Zie bijlage A voor contactgegevens, en website.</li></ul></li>
      <li>Als je klachten hebt over de verwerking van jouw persoonsgegevens, raden we je aan om deze eerst te bespreken met het onderzoeksteam. Je kunt ook naar de Functionaris Gegevensbescherming van Universiteit Leiden gaan. Of je dient een klacht in bij de Autoriteit Persoonsgegevens. </li>
      </ul>
      <i>Waar vind je meer informatie over het onderzoek?</i><br />
      Op de volgende website vind je meer informatie over het onderzoek: <a href="https://www.ClinicalTrials.gov" target="_blank">www.ClinicalTrials.gov</a>. Na het onderzoek kan de website een samenvatting van de
      resultaten van dit onderzoek tonen. Je vindt het onderzoek door te zoeken op NCT06023693.<br />
      <br />
      <strong>11. Krijg je een vergoeding als je meedoet aan het onderzoek?</strong><br />
      Het programma voor het onderzoek kost je niets. Je krijgt ook geen vergoeding als je meedoet aan dit onderzoek. Wel krijg je een vergoeding voor het invullen van de vragenlijsten, namelijk eenmalig een 15€ VVV-kaart, voor de vragenlijst na afloop van het programma en voor de laatste vragenlijst kun je deelnemen aan een loterij voor 8x 50€ VVVkaarten.<br />
      <br />
      <strong>12. Ben je verzekerd tijdens het onderzoek?</strong><br />
      Je bent niet extra verzekerd voor dit onderzoek. Want meedoen aan het onderzoek heeft geen extra risico's. Daarom hoeft de Universiteit Leiden van de METC Leiden Den Haag Delft geen extra verzekering af te sluiten.<br />
      <br />
      <strong>13. Heb je vragen?</strong><br />
      Vragen over het onderzoek kun je stellen aan het onderzoeksteam. In bijlage A staan de contactgegevens van de onderzoekers aan wie je de vragen kan stellen. Je kunt je vragen ook mailen naar <a href="mailto:isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a>.<br />
      <br />
      Heb je een klacht? Bespreek dit dan met de onderzoeker of de arts die je behandelt. Wil je dit liever niet? Ga dan naar de klachtenfunctionaris van de Universiteit Leiden, Eugène van der Heijden. In bijlage A staat waar je die kunt vinden.<br />
      <br />
      <strong>14. Hoe geef je toestemming voor het onderzoek?</strong><br />
      Je kunt eerst rustig nadenken over dit onderzoek. Je kunt vragen naar de onderzoeker mailen en dan beslissen. Wil je meedoen? Dan vul je het online toestemmingsformulier in waarin je aangeeft dat je alles hebt begrepen en dat je mee wilt doen.<br />
      <br />
      Dank voor jouw tijd. <br />
      <br />
      <br />
      <br />
      <strong>Bijlage A: contactgegevens voor Universiteit Leiden</strong><br />
      <br />
      <u>Onderzoekers:</u><br />
      Dr. Niki Antypa, Principal Investigator/ Associate Professor,<br />
      Afdeling Klinische Psychologie, Universiteit Leiden, E-mail: <a href="mailto:nantypa@fsw.leidenuniv.nl">nantypa@fsw.leidenuniv.nl</a>,<br />
      Bereikbaarheid: Ma, Di, Do, Vr<br />
      <br />
      Laura Pape (MSc.), PhD Candidate,<br />
      Afdeling Klinische Psychologie, Universiteit Leiden, E-mail: <a href="mailto:l.m.pape@fsw.leidenuniv.nl">l.m.pape@fsw.leidenuniv.nl</a>,<br />
      Bereikbaarheid: Ma - Vr<br />
      <br />
      <u>Deskundige/ Persoon medisch verantwoordelijk voor proefpersonen:</u><br />
      Dr. Sascha Struijs, Gezondheidszorgpsycholoog en coördinator MoodLift,<br />
      Afdeling Klinische, Neuro en Ontwikkelingspsychologie, Vrije Universiteit Amsterdam,<br />
      E-mail: <a href="mailto:s.y.struijs@vu.nl">s.y.struijs@vu.nl</a><br />
      <br />
      <u>Onafhankelijk deskundige:</u><br />
      Dr. Julia van den Berg, Klinisch Psycholoog en Epidemioloog,<br />
      Afdeling Klinische Psychologie, Universiteit Leiden, E-mail: <a href="mailto:j.f.van.den.berg@fsw.leidenuniv.nl">j.f.van.den.berg@fsw.leidenuniv.nl</a><br />
      <br />
      <strong>Klachten:</strong><br />
      Klachtenfunctionaris van de Universiteit Leiden<br />
      Eugène van der Heijden, E-mail: <a href="mailto:ombudsfunctionaris@leidenuniv.nl">ombudsfunctionaris@leidenuniv.nl</a> , Telefoon: 071 527 3657<br />
      <a href="https://www.student.universiteitleiden.nl/administratie--organisatie/klacht-beroep--bezwaar#tab-1" target="_blank">Link naar de webpagina</a><br />
      <br />
      <strong>Voor meer informatie over uw rechten:</strong><br />
      Functionaris voor de Gegevensbescherming van de Universiteit Leiden:<br />
      Raymond van Erkel, E-mail: <a href="mailto:r.van.erkel@fsw.leidenuniv.nl">r.van.erkel@fsw.leidenuniv.nl</a>,<br />
      Bereikbaarheid: Ma - Do<br />
      <br />
      <br />
      <br />
      <strong>Bijlage B: Schema overzicht metingen</strong><br />
      <br />
      <strong>Wat houd deelname in?</strong><br />
      Nadat je hebt aangemeld op het MoodLift platform, word je eerst gevraagd een reeks online vragenlijsten in te vullen, wat ongeveer 15 tot 20 minuten duurt (T0). Vervolgens word je ingedeeld naar het i-Sleep & BioClock programma of het online psycho-educatie programma. In de eerste week houd je een slaapdagboek bij, waarvoor je op het platform gaat inloggen. Dit duurt ongeveer 3 minuten per dag en kan gedaan worden via de smartphone. Dit slaapdagboek maakt ook deel uit van het programma zelf, maar is tijdens het programma optioneel in te vullen. Na 3 weken (T1) vragen we je om een zeer korte vragenlijst in te vullen (halverwege het programma). Na 6 weken (aan het einde van het programma, T2) vragen we je opnieuw om het slaapdagboek 7 dagen lang bij te houden. Zo krijg je inzicht in waar je nu staat en hoe je slaap is veranderd. Op hetzelfde moment (na 6 weken, T2), sturen we jou een e-mail met een link naar dezelfde set vragenlijsten als aan het begin voor de start van het programma, en nogmaals 18 weken na registratie (T3). De laatste vragenlijst is wat korter, en duurt ongeveer 10-15 minuten om in te vullen. Je kan telefonisch worden herinnerd aan het invullen van de vragenlijsten. De compensatie voor het invullen
      van de vragenlijsten volgt in week 6 (15€ VVV-bon eenmalig voor de ingevulde post-test vragenlijst), en in week 18 (voor de volledig ingevulde vragenlijst maak je kans op een VVVbon van 50€).<br />
      <br />
      <img src="/uploads/intervention/47/rct_schema_nl.png" alt="rct_schema_nl.png" /><br />
      <br />
      <br />
      <ul>
        <li>Ik heb de informatiebrief gelezen. Ook kon ik vragen stellen via de mail. Mijn vragen zijn goed genoeg beantwoord. Ik had genoeg tijd om te beslissen of ik meedoe.</li>
        <li>Ik weet dat meedoen vrijwillig is. Ook weet ik dat ik op ieder moment kan beslissen om toch niet mee te doen met het onderzoek. Of om ermee te stoppen. Ik hoef dan niet te zeggen waarom ik wil stoppen.</li>
        <li>Ik geef de onderzoekers toestemming om mijn gegevens te verzamelen en gebruiken. De onderzoekers doen dit alleen om de onderzoeksvraag van dit onderzoek te beantwoorden.</li>
        <li>Ik weet dat voor de controle van het onderzoek sommige mensen al mijn gegevens kunnen inzien. Die mensen staan in deze informatiebrief. Ik geef deze mensen toestemming om mijn gegevens in te zien voor deze controle. </li>
      </ul>
      `,
      2: `<h3>Subject information for participation in medical research</h3><br />
      <br />
      <strong>i-Sleep & BioClock for students</strong><br />
      A study on the effectiveness of a guided e-health sleep and biological clock intervention in university students (iSleep & BioClock)<br />
      <br />
      <strong>Introduction</strong><br />
      Dear (PhD) student,<br />
      <br />
      With this information letter, we would like to ask you if you would like to participate in medical-scientific research. Participation is voluntary. You are receiving this information letter because you have sleep problems. Here you can read what kind of research this involves, what it means for you, and what the advantages and disadvantages are. It's a lot of information. Read the information carefully to decide whether you want to participate. If you want to participate, sign the online consent form.<br />
      <br />
      <strong>Ask you questions</strong><br />
      You can take your decision based on the information in this information sheet. We also suggest that
      you do this:<br />
      <ul>
      <li>Ask questions to the researcher who gives you this information. You can email your questions to the researcher at <a href="mailto:isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a></li>
      <li>Talk to your partner, family or friends about this research.</li>
      <li>Read the information on <a href="https://www.rijksoverheid.nl/mensenonderzoek" target="_blank">www.rijksoverheid.nl/mensenonderzoek</a>.</li>
      </ul>
      <strong>1. General information</strong><br />
      The study was designed at Leiden University (Department of Clinical Psychology). Below we always refer to Leiden University as the 'sponsor'. Researchers, who may also be clinical psychologists, conduct the research in various universities. The independent expert of this research is Dr. Julia van den Berg (Leiden University).<br />
      <br />
      Participants in a medical-scientific study are often called test subjects. Both patients and healthy people can be test subjects.<br />
      <br />
      This research requires 192 students from various universities and colleges. The medical ethics review committee METC Leiden-The Hague-Delft has approved this research.<br />
      <br />
      <strong>2. What is the purpose of this study?</strong><br />
      In this study we look at how well i-Sleep & BioClock works for reducing sleep problems in students. i-Sleep & BioClock is a self-help program based on the principles of Cognitive Behavioral Therapy (CBT). CBT is a widely used treatment for mental problems, which involves learning to feel better by learning to think and act differently.<br />
      We compare the effect of i-Sleep & BioClock with the effect of online psychoeducation.<br />
      Psychoeducation is another word for information. Online psychoeducation is the most common way to learn to deal with sleep complaints.<br />
      <br />
      <strong>3. What is the background of this study?</strong><br />
      Many students suffer from sleep problems. Sleep problems can affect many different aspects of life, such as mood, stress, performance and quality of life. In addition, long-term sleep problems are associated with several mental disorders, such as depression and anxiety disorders. We have developed a guided online self-help program for students based on principles of cognitive behavioral therapy for insomnia. It focuses on understanding and addressing problematic thoughts and behaviors that contribute to emotional problems and insomnia. By recognizing and adjusting these, you can start to feel better and deal better with situations that were previously difficult. The program lasts approximately 5 weeks in total. The topics discussed are the biological clock, changing behavior that hinders sleep (sleep hygiene), changing sleep patterns, relaxation, and dealing with worrying and negative thoughts about sleep.<br />
      <br />
      <strong>4. What happens during this study?</strong><br />
      <i>How long will the study take?</i><br />
      Are you taking part in the study? It will take about 4,5 months in total, of which the first 5 weeks are more intensive.<br />
      <br />
      Step 1: are you eligible to take part?<br />
      We first want to know whether you are eligible to participate. That is why we first administer a number of questionnaires. You answer the questionnaires online via the MoodLift platform. If it turns out that you are not eligible to participate, you will receive an email with further explanation. If you are suitable to participate, you can start with the online program assigned to you (see step 2).<br />
      <br />
      <i>Step 2: the program's</i><br />
      You will follow an online self-help program for 5-6 weeks. The program is on an online platform. You can log in via your laptop, tablet or telephone and do not need any additional software.<br />
      <br />
      For this study, we will have 2 groups:<br />
      <ul>
      <li>Group 1. Students in this group receive the online i-Sleep & BioClock program (a self-help program based on cognitive behavioral therapy for insomnia).</li>
      <li>Group 2. Students in this group receive online psychoeducation (information) for insomnia.</li>
      </ul>
      A draw determines whether you are in group 1 or group 2. The chance of being randomized (assigned by chance) to one of the two groups is 50/50.<br />
      <br />
      <i>Step 3: study and measurements</i><br />
      You create an account for the MoodLift platform with your study mail address. When you register, you can choose your personal coach. We recommend that you choose a coach that you do not know personally. You can choose to register anonymously. This means that the coach cannot see your name and personal information such as your email address. Before the program starts, you will complete online questionnaires. Completing a questionnaire takes about 15-20 minutes. We ask you to complete questionnaires at four measurement points: at the start of the program, halfway through the program (a very short questionnaire 3 weeks after the start), at the end of the program (6 weeks after the start), and again 18 weeks after start. A few days after registration you will be called for an intake call with the coach, or if you have been registered anonymously by the researcher. During the research you will be reminded to complete the questionnaires by email and/or telephone. You will also keep a sleep diary in the first week, for which you will log in to the platform. This takes about 3 minutes and can be completed on your smartphone. This sleep diary is also part of the program itself. After 6 weeks (at the end of the program) we will ask you again to keep the sleep diary. This gives you insight into where you are now and how your sleep has changed. Participation also means that you actively work on the self-help program. Each program contains several modules that you work on for between 30 and 60 minutes. We advise you to work on 1 to a maximum of 2 modules per week. If you are in group 1, you will also receive textual feedback on the answers in the modules from your e-coach. If you are in group 2 you will not receive coaching.<br />
      <br />
      <strong>5. What agreements do we make with you?</strong><br />
      We want the study to go well. That is why we want to make the following agreements with you:<br />
      <ul>
      <li>You actively participate in the program.</li>
      <li>You complete the questionnaires and the sleep diary.</li>
      <li>You should contact the investigator in these situations:<ul>
      <li>You are hospitalized or get treatment in a hospital.</li>
      <li>You suddenly have problems with your health.</li>
      <li>You no longer want to take part in the study.</li>
      <li>Your telephone number, or email address changes.</li>
      </ul></li>
      </ul>
      <strong>6. What side effects, adverse effects or discomforts could you experience?</strong><br />
      The programs and procedures used in this study are safe and pose no risks. All procedures performed in this study are safe and simple, as they have been used previously in different patient populations. However, it is in principle possible that you may experience discomfort while completing the questionnaires. In addition, participation in this study takes some time and effort. No adverse side effects are expected. If you experience any adverse effects during the study, please let your e-coach know or contact the researchers or the person medically responsible for this study (S. Struijs, for contact details see Appendix A). We have an additional protocol in place for crisis situations (such as thoughts of self-harm or suicide).<br />
      <br />
      <i>Are you still unsure whether you want to participate?</i><br />
      <br />
      You decide for yourself whether you want to participate in the study. Participation is voluntary. If you decide to participate in the study, you can always change your mind. You may stop during the research. You don't have to say why you're quitting. However, you must report this immediately to the researcher. The data collected up to that point will be used for the research.<br />
      <br />
      <strong>7. What are the pros and cons if you take part in the study?</strong><br />
      Taking part in the study can have pros and cons. We will list them below. Think about this carefully and talk to other people about it. A possible advantage of participating in this study is that the self-help programs are available 24 hours a day. You can work on it at your own pace. A consequence of working with these programs is that your symptoms may improve and your quality of life may increase. The self-help program is <u>not therapy</u>, but a <u>self-help</u> program that allows you to gain more control over your sleep problems. With your participation you help the researchers to gain more insight into solving insomnia in students. We do not expect that participating in this study will entail any risks.
      <br />
      Participating in the study may have these disadvantages:<br />
      <ul>
      <li>Participating in the study will cost you extra time.</li>
      <li>You have to comply with the study agreements.</li>
      </ul>
      <i>You do not wish to participate in the study?</i><br />
      It is up to you to decide if you wish to participate in the study. If you decide not to participate in the study but would like to get help for your sleep complaints, you can contact a doctor or psychologist to discuss and treat your complaints.<br />
      <br />
      <strong>8. When does the study end?</strong><br />
      The investigator will let you know if there is any new information about the study that is important to you. The investigator will then ask you if you want to continue to take part.<br />
      <br />
      In these situations, the study will stop for you:<br />
      <ul>
      <li>All checks according to the schedule are finished.</li>
      <li>You want to stop participating in the study yourself. You can stop at any time. Report this to the investigator immediately via isleep-bioclock@fsw.leidenuniv.nl. You do not have to explain why you want to stop. The researcher can give you other points of contact for treatment.</li>
      <li>The investigator thinks it is better for you to stop. The investigator will still invite you for a follow-up check.</li>
      <li>One of the following authorities decides that the study should stop:<ul>
      <li>Leiden University,</li>
      <li>the government, or</li>
      <li>the Medical Ethics Review Committee assessing the study.</li>
      </ul></li>
      </ul>
      <i>What happens if you stop participating in the study?</i><br />
      The researchers use the data collected up to the moment that you decide to stop participating in the study. The entire study ends when all participants have completed their program and follow-up period.<br />
      <br />
      <strong>9. What happens after the study has ended?</strong><br />
      <i>Can you continue to use the program?</i><br />
      If you are in group 1, you can continue to use the i-Sleep & BioClock program for another year and a half. So you have access to the platform for a total of 2 years. The coaching period ends after a maximum of 8 weeks, which means that you will no longer receive feedback from the coach, even if you have not yet completed all modules. If you are in group 2 (psychoeducation), you can gain access to the i-Sleep & BioClock program after the research (approximately 4.5 months) if you are still a student at the participating universities. You will receive an additional email for this. This program is available to you for 2 years from then on.<br />
      <br />
      <i>Will you receive the results of the research?</i><br />
      About 1 year after the study has been completed, the researcher will let you know what the most important results of the study are. Do you prefer not to know? Please tell the investigator. He/she will not tell you in that case.<br />
      <br />
      <strong>10. What do we do with your data?</strong><br />
      Are you taking part in the study? Then you also give your consent to collect, use and store your data. <br />
      <br />
      What data do we store?<br />
      We store these data:<br />
      <ul>
      <li>your name</li>
      <li>your gender</li>
      <li>your address</li>
      <li>your date of birth</li>
      <li>information about your health</li>
      <li>(medical) information that we collect during the study (e.g. sleep diary data, your answers to the assignments in the program, and log data)</li>
      </ul>
      <i>Why do we collect, use and store your data?</i><br />
      We collect, use and store your data to answer the questions of this study. And to be able to publish the results. Data can be used by the sponsor and companies that help the sponsor analyze research data.<br />
      <br />
      <li>How do we protect your privacy?</li><br />
      To protect your privacy, we give a code to your data. We put this code on all of your data. We keep the key to the code in a safe place on a secured drive from the university, which is only accessible for the researcher. When we process your data and body material, we always use only that code. Even in reports and publications about the study, nobody will be able to see that it was about you. <br />
      <br />
      <li>What about the security of the MoodLift platform?</li><br />
      You communicate via the platform via a chat with your coach. The communication between the users of the MoodLift website and the web server is encrypted and cannot be intercepted en route.<br />
      <br />
      <i>Who can see your data?</i><br />
      Some people can see your name and other personal information without a code. This may include data collected specifically for this study.<br />
      <br />
      These are people checking whether the investigators are carrying out the study properly and reliably. These persons can access your data:<br />
      <ul>
      <li>The researchers Dr. Niki Antypa (principal investigator), Dr. Sascha Struijs (coordinator of MoodLift), and Laura Pape (PhD student)</li>
      <li>The personal e-coach and supervising psychologists have partial access to the data unless you register anonymously. This only happens in accordance with professional standards regarding anonymity and security as established in the professional code of the Dutch Institute of Psychologists (<a href="https://www.psynip.nl/uw-beroep/beroepsethics/beroepscode/" target="_blank">www.psynip.nl/uw-beroep/beroepsethics/beroepscode/</a>).</li>
      <li>Employees of the company that manages the MoodLift platform only have access to the data when it is necessary for the maintenance of the platform (e.g. to fix bugs on the platform). All personal data will be treated strictly confidential and in accordance with the GDPR. This is contractually agreed upon.</li>
      <li>Members of the committee that keeps an eye on the safety of the study.</li>
      <li>A data protection officer of Leiden University.</li>
      <li>National and international supervisory authorities.</li>
      </ul>
      These people will keep your information confidential. We ask you to give permission for this access. The Health and Youth Inspectorate can access your personal information without your permission.<br />
      <br />
      <i>For how long do we store your data?</i><br />
      We store your data at Leiden University for 15 years.<br />
      <br />
      <i>Can we use your data for other research?</i><br />
      Your collected data may also be important for other scientific research in the field of insomnia in students and the further development of its treatment. For this purpose, your data will be kept at the university for 15 years. You indicate in the consent form whether you agree with this. Do you not give permission? Then you can still participate in this study. You will receive the same care.<br />
      <br />
      <i>Can you take back your consent for the use of your data?</i><br />
      You can take back your consent for the use of your data at any time. Please tell the investigator if you wish to do so. This applies both to the use in this study and to the use in other medical research. But please note: if you take back your consent, and the investigators have already collected data for research, they are still allowed to use this information.<br />
      <br />
      <i>Do you want to know more about your privacy?</i><br />
      <ul>
      <li>Do you want to know more about your rights when processing personal data? Visit <a href="https://www.autoriteitpersoonsgegevens.nl" target="_blank">www.autoriteitpersoonsgegevens.nl</a>.</li>
      <li>Do you have questions about your rights? Or do you have a complaint about the processing of your personal data? Please contact the person who is responsible for processing your personal data. For the present, this is:<ul><li>Raymond van Erkel [Privacy officer, Universiteit Leiden]<br/>
      See appendix A for contact details and website.</li></ul></li>
      </ul>
      If you have any complaints about the processing of your personal data, we recommend that you first discuss them with the research team. You can also contact the Data Protection Officer of Leiden University Or you can submit a complaint to the Dutch Data Protection Authority.<br />
      <br />
      <i>Where can you find more information about the study?</i><br />
      You will find more information about the research on the following website: <a href="https://www.ClinicalTrials.gov" target="_blank">www.ClinicalTrials.gov</a>. After the research, the website can show a summary of the results of this research. You can find the study by searching study number NCT06023693.<br />
      <br />
      <strong>11. Will you receive compensation if you participate in the study?</strong><br />
      Participating in this study does not cost you anything. You will receive compensation for completing the questionnaires, namely a one-off €15 VVV voucher, for the questionnaire after the program and for the last questionnaire you can participate in a lottery for 8x €50 VVV vouchers.<br />
      <br />
      <strong>12.Are you insured during the study?</strong><br />
      You are not additionally insured for this study. Because taking part in the study has no additional risks. That is why Leiden University does not have to take out additional insurance, which has been agreed by the METC Leiden-The Hague-Delft.<br />
      <br />
      <strong>13.Do you have any questions?</strong><br />
      You can ask questions about the study to the researchers. Appendix A contains the contact details of the researchers to whom you can ask the questions. You can also email your questions to <a href="mailto:isleep-bioclock@fsw.leidenuniv.nl">isleep-bioclock@fsw.leidenuniv.nl</a>.<br />
      <br />
      Do you have a complaint? Then discuss this with the researcher or the doctor who is treating you. Would you rather not do this? Then go to the complaints officer of Leiden University, Eugène van der Heijden. Appendix A shows where you can find him.<br />
      <br />
      <strong>14.How do you give consent for the study?</strong><br />
      You can first think carefully about this study. Then you tell the investigator if you understand the information and if you want to take part or not. If you want to take part, fill in the consent form that you can find with this information sheet. You and the investigator will both get a signed version of this consent form.
      <br />
      Thank you for your attention.<br />
      <br />
      <br />
      <br />
      <strong>Appendix A: contact details Leiden University</strong><br />
      <br />
      Researchers:<br />
      Dr. Niki Antypa, Principal Investigator/ Associate Professor,<br />
      Department Clinical Psychology, Leiden University, E-mail: <a href="mailto:nantypa@fsw.leidenuniv.nl">mailto:nantypa@fsw.leidenuniv.nl</a>,<br />
      Availability: Mo, Tue, Thur, Fri<br />
      <br />
      Laura Pape (MSc.), PhD Candidate,<br />
      Department Clinical Psychology, Leiden University, E-mail: <a href="mailto:l.m.pape@fsw.leidenuniv.nl">mailto:l.m.pape@fsw.leidenuniv.nl</a>,<br />
      Availability: Mo - Fri <br />
      <br />
      Person medically responsible for subjects:<br />
      Dr. Sascha Struijs, Psychologist and coordinator of MoodLift,<br />
      Department of Clinical, Neuro and Developmental Psychology, Vrije Universiteit Amsterdam,<br />
      E-mail: <a href="mailto:s.y.struijs@vu.nl">mailto:s.y.struijs@vu.nl</a><br />
      <br />
      Independent expert:<br />
      Dr. Julia van den Berg, Clinical Psychologist and Epidemiologist,<br />
      Department Clinical Psychology, Leiden University, E-mail: <a href="mailto:j.f.van.den.berg@fsw.leidenuniv.nl">j.f.van.den.berg@fsw.leidenuniv.nl</a><br />
      <br />
      <strong>Complaints:</strong><br />
      Complaints officer of Leiden University<br />
      Eugène van der Heijden, E-mail: <a href="mailto:ombudsfunctionaris@leidenuniv.nl">ombudsfunctionaris@leidenuniv.nl</a> , Telephone: <a href="tel:0715273657">071 527 3657</a><br />
      <a href="https://www.student.universiteitleiden.nl/studie-en-studeren/begeleiding-en-advies/klachten/onbehoorlijk-behandeld/geneeskunde-lumc/geneeskunde-bsc?cf=geneeskunde-lumc&cd=geneeskunde-bsc#tab-1" target="_blank">Link to webpage</a><br />
      <br />
      <strong>For more information about your rights:</strong><br />
      Data Protection Officer of Leiden University:<br />
      Raymond van Erkel, E-mail: <a href="mailto:r.van.erkel@fsw.leidenuniv.nl">r.van.erkel@fsw.leidenuniv.nl</a>,<br />
      Availability: Mo - Thur<br />
      <br />
      <strong>Appendix B: Schematic overview of measurements</strong><br />
      <br />
      <strong>What does participation entail?</strong><br />
      After logging into the MoodLift platform, you will first be asked to complete a series of online questionnaires, which will take approximately 15 to 20 minutes (T0). You will then be assigned to the i-Sleep & BioClock program or the online psychoeducation program. In the first week you will keep a sleep diary, for which you will log in to the platform. This takes about 3 minutes per day and can be done via smartphone. This sleep diary is also part of the program itself, but can be completed optionally during the program. After 3 weeks (T1) we ask you to complete a very short questionnaire (halfway through the program). After 6 weeks (at the end of the program, T2) we will ask you again to keep the sleep diary for 7 days. This gives you insight into where you are now and how your sleep has changed. At the same time (after 6 weeks, T2), we will send you an email with a link to the same set of questionnaires as at the beginning before the start of the program, and again 18 weeks after      registration (T3). The last questionnaire is a bit shorter, taking about 10-15 minutes to complete. You can be reminded by telephone to complete the questionnaires. Compensation for completing the questionnaires will follow in week 6 (€15 one-off VVV voucher for the completed post-test questionnaire), and in week 18 (for the fully completed questionnaire you have a chance to win a €50 VVV voucher).<br />
      <br /><img src="/uploads/intervention/47/rct_schema_nl.png" alt="rct_schema_eng.png" /><br />
      <br />
      <br />
      <ul>
        <li>I have read the information sheet. I was able to ask questions. My questions have been answered well enough. I had enough time to decide if I wanted to take part.</li>
        <li>I know that taking part is voluntary. I also know that at any time I can decide not to take part in the study. Or to stop taking part. I do not have to explain why.</li>
        <li>I give the researchers permission to collect and use my data. The researchers only do this to answer the research question of this study.</li>
        <li>I know that some people will be able to see all of my data to review the study. These people are mentioned in this information sheet. I give consent to let them see my data for this review.</li>
      </ul>`
      
    }
  }

  const [agree, setAgree] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [suicide, setSuicide] = useState(false);
  const [languageCode, setLanguageCode] = useState('nl')
  const [email, setEmail] = useState('');
  const [steps, setSteps] = useState(3);

   useEffect(() => {
      if (registrationData.language_id === 1) {
        setLanguageCode('nl')
      } else {
        setLanguageCode('eng')
      }
   }, [registrationData.language_id])

   useEffect(() => {
      setAgree(registrationData.agree)
      setFollowUp(registrationData.followUp)
      setSuicide(registrationData.suicide)
      setEmail(registrationData.email)

      //registratie RCT LifeHack en i-SLeep hebben een stap meer
      if (registrationData.intervention_id === 45 || registrationData.intervention_id === 47) {
        setSteps(4);
      }
   }, [registrationData])

  const changeEmail = (e) => {
    setEmail(e.target.value);
  }

  const changeAgree = (e) => {
    setAgree(!agree);
  }

  const changeFollowUp = (e) => {
    setFollowUp(!followUp);
  }

  const changeSuicide = (e) => {
   setSuicide(!suicide)
  }

  const [notificationOptions, setNotificationOptions] = useState('');

  const goToStep = (step) => {

    if (step === 4) {
      //check email
      if (validateEmail(email)) {
        let mailparts = email.split("@");
        if (registrationTexts.panel3.mailDomains.indexOf(mailparts[1]) > -1) {
          if (agree) {
            if (registrationTexts.panel3.suicideQuestion.indexOf(registrationData.intervention_id.toString()) > -1 && !suicide) {
             setNotificationOptions({
                show: "true",
                text: t("Tekst mbt tot het niet aanvinken van de suicide vraag..."),
                confirmText: t("Ok")
              });
            } else {
                let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
                //update status qualtrics
                if (registrationDataTemp.qualtrics_id.length > 0) {
                  apiCall({
                    action: "update_qualtrics_id",
                    data: {
                      qualtrics_id: registrationDataTemp.qualtrics_id
                    }
                  })
                }
                //onderdeel van RCT met een vragenlijst tijdens de registratie?
                //RCT LifeHack #45, RCT i-Sleep #47
                if (registrationData.intervention_id === 45 || registrationData.intervention_id === 47) {
                  registrationDataTemp.step = '3a'
                } else {
                  registrationDataTemp.step = 4             
                }
                registrationDataTemp.agree = agree
                registrationDataTemp.followUp = followUp
                registrationDataTemp.suicide = suicide
                registrationDataTemp.email = email
                setRegistrationData(registrationDataTemp)
            }
          } else {
            setNotificationOptions({
              show: "true",
              text: t("Om deel te nemen dien je onderaan het formulier aan te geven dat je akkoord gaat met de op deze pagina beschreven voorwaarden voor deelname. MoodLift voldoet aan alle AVG eisen en zorgt er voor dat jouw data veilig en privé blijft."),
              confirmText: t("Ok")
            });
          }
        } else {
          setNotificationOptions({
            show: "true",
            text: registrationTexts.panel3.wrongUni[languageCode],
            confirmText: t("Ok")
          });
        }
      } else {
        setNotificationOptions({
          show: "true",
          text: t("Geef een geldig e-mailadres op!"),
          confirmText: t("Ok")
        });
      }
    }
    else {
      let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
      registrationDataTemp.step = 2
      setRegistrationData(registrationDataTemp)
      window.scrollTo(0,0)
    }
  }

  return (
      <>
       <div className="step2">
         <button type="button" className="btn prev" onClick={() => goToStep(2)}>{t("Terug")}</button>
        <div className="container">
          <div className="step">
            <b>{t("stap 1 van") + ' ' + steps}</b> {t("informed consent")}
          </div>
          <h1>{texts.title[registrationData.language_id]}</h1>
          {
            registrationData.intervention_id === 47 ?
            <p dangerouslySetInnerHTML={{__html: iSleepTexts.intro[registrationData.language_id]}}></p>
            :
            <p>{texts.intro[registrationData.language_id]}</p>
          }
          <div className="informedConsent">
            <div className="content" dangerouslySetInnerHTML={typeof alternativeInformedConscent[registrationData.intervention_id] === "undefined" ? {__html: registrationTexts.panel3.informedConsent[languageCode]} : {__html: alternativeInformedConscent[registrationData.intervention_id][registrationData.language_id]}}></div>
            <div className="form-group bigLabel">
              <input
                className="form-check-input"
                type="checkbox"
                id="agree"
                onChange={changeAgree}
                checked={agree || ''}
              />
              <label className="form-check-label" htmlFor="agree" dangerouslySetInnerHTML={{__html: registrationTexts.panel3.question1[languageCode]}} />
              <br/>
              <br/>
              <input
                className="form-check-input"
                type="checkbox"
                id="follow-up-research"
                onChange={changeFollowUp}
                checked={followUp || ''}
              />
              <label className="form-check-label" htmlFor="follow-up-research" dangerouslySetInnerHTML={{__html: registrationTexts.panel3.question3[languageCode]}} />

               {
                  registrationTexts.panel3.suicideQuestion.indexOf(registrationData.intervention_id.toString()) > -1 ?
                     <>
                        <br/>
                        <br/>
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="suicide"
                         onChange={changeSuicide}
                         checked={suicide || ''}
                       />
                       <label className="form-check-label" htmlFor="suicide" dangerouslySetInnerHTML={{__html: registrationTexts.panel3.question4[languageCode]}} />
                     </> :<></>
                }        
            </div>
          </div>

          <div className="form-group">
            {t("Vul hier je studentenmailadres in")}
            <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="email"
                placeholder={t("Typ hier")}
                value={email || ''}
                onChange={(e) => changeEmail(e)}
                autoCorrect="off" 
                autoCapitalize="none"
            />
            <br /><em style={{fontSize: '0.8em'}}><strong>{t("Voor UvA studenten")}:</strong> {t("Let op! Het student e-mailadres van de UvA eindigt altijd op @student.uva.nl en je studentennummer komt niet voor in het e-mailadres. Lukt aanmelden niet? Check bij de UvA wat je UvA e-mailadres is.")}</em>
          </div>

          <div className="navigation">
              <button type="button" className="btn btn-primary next" onClick={() => goToStep(4)}>{t("Doorgaan")}</button>
          </div>
          
         <Universities step={3} registrationTexts={registrationTexts} languageCode={languageCode} />


        </div>

        <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
       </div>
       {/* <Footer language={registrationData.language_id} changeLanguage={changeLanguage} /> */}
    </>
  )

}

export default Step3;
