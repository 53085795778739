import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../api";
import moment from "moment";
import LeftMenu from "../leftmenu";
import t from "../translate";
import { setIntervention } from "../../actions";
import SortObjectArray from "../helpers/sortObjectArray.js";
import { useHistory } from "react-router-dom";
import {GetDate, GetTime} from "../helpers/convertTimestamp.js";

const Data = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const uiTranslation = useSelector(state => state.uiTranslation);

  useEffect(() => {
    let intervention_id = window.location.href.split("/")[5];
    if (intervention.id === 0 && intervention_id !== intervention.id) {
      apiCall({
        action: "get_intervention_settings",
        token: auth.token,
        data: {
          intervention_id,
          language_id: uiTranslation.language_id
        }
      }).then(resp => {
        dispatch(
          setIntervention(
            resp.intervention_id,
            resp.organisation_id,
            resp.title,
            resp.settings
          )
        );
      });
    }
  }, []);

  function downloadRegistrationsCSV() {

    //api aanroepen
    apiCall({
      action: "get_registrations",
      token: auth.token,
      data: {
        intervention_id: intervention.id
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = 'id;date_time_create;date_time_update;email;intervention_id;language_id;follow_up_research;age;gender;qualtricsLongerThen1WeekAgo;t0_started;t0_finished;IPS_score;PHQ9P_total_score;PHQ9P_item9_score;G10_score;G11_score;included;nationality;university;faculty;learnedabout;learnedAboutother;account_created;account_activated;user_id;rando_university;rando_gender;rando_group\n';
      var dataString = '';
      var scores = '';
      resp.registrations.forEach(function(r, index) {
        if (r.t0_started > 0)
        {
          r.t0_started = moment.unix(r.t0_started).format("DD-MM-YYYY HH:mm:ss", { trim: false });
        }
        if (r.t0_finished > 0)
        {
          r.t0_finished = moment.unix(r.t0_finished).format("DD-MM-YYYY HH:mm:ss", { trim: false });
        }
        let IPS_score = -99;
        if (typeof r.t0_scores !== 'undefined' && typeof r.t0_scores.IPS_score !== 'undefined') {
          IPS_score = r.t0_scores.IPS_score;
        }
        let PHP9P_total_score = 0;
        let PHP9P_item9_score = 0;
        let G10_score = 0;
        let G11_score = 0;
        if (typeof r.t0_scores !== 'undefined' && typeof r.t0_scores.PHP9P_total_score !== 'undefined')
        {
          
          PHP9P_total_score = r.t0_scores.PHP9P_total_score;
          PHP9P_item9_score = r.t0_scores.PHP9P_item9_score;
          G10_score = r.t0_scores.G10_score;
          G11_score = r.t0_scores.G11_score;
        }
        if (r.account_created > 0)
        {
          r.account_created = moment.unix(r.account_created).format("DD-MM-YYYY HH:mm:ss", { trim: false });
        }
        if (r.account_activated > 0)
        {
          r.account_activated = moment.unix(r.account_activated).format("DD-MM-YYYY HH:mm:ss", { trim: false });
        }
        dataString += r.id +";"+ moment.unix(r.date_time_create).format("DD-MM-YYYY HH:mm:ss", { trim: false })+";"+moment.unix(r.date_time_update).format("DD-MM-YYYY HH:mm:ss", { trim: false })+";"+r.email+";"+ intervention.id +";"+ r.language_id +";"+r.follow_up_research+";"+r.age+";"+r.gender+";"+r.qualtricsLongerThen1WeekAgo+";"+r.t0_started+";"+r.t0_finished+";"+IPS_score+";"+PHP9P_total_score+";"+PHP9P_item9_score+";"+G10_score+";"+G11_score+";"+r.included+";"+r.nationality+";"+r.university+";"+r.faculty+";"+r.learnedabout+";"+r.learnedaboutOther+";"+r.account_created+";"+r.account_activated+";"+r.user_id+";"+r.rando_university+";"+r.rando_gender+";"+r.rando_group+"\n";
        //csvContent += index < registrations.length ? dataString + '\n' : dataString;
      
      });
      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, t('download registraties ') + intervention.title + '.csv', 'text/csv;encoding:utf-8');
    });
   
  }

  function downloadQualtricsRegistrationsCSV() {

    //api aanroepen
    apiCall({
      action: "get_qualtrics_registrations",
      token: auth.token,
      data: {
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = 'id;date_time_create;date_time_update;qualtrics_id;status;registration_id;\n';
      var dataString = '';
      var scores = '';
      resp.registrations.forEach(function(r, index) {

        dataString += r.id +";"+ moment.unix(r.date_time_create).format("DD-MM-YYYY HH:mm:ss", { trim: false }) +";"+ moment.unix(r.date_time_update).format("DD-MM-YYYY HH:mm:ss", { trim: false }) +";"+ r.qualtrics_id +";"+ r.status +";"+r.registration_id+"\n";
      
      });
      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, 'qualtrics_registrations_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');
    });
   
  }

  const downloadQuestionnaireCSV = (questionnaire_id) => {
    apiCall({
      action: "get_questionnaire_csv_rows",
      token: auth.token,
      data: {
        questionnaire_id
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = '';
      for (var header of resp.headers) {
        csvContent += header+';';
      }
      csvContent += '\n';
      var dataString = '';
      for (var row of resp.rows) {
        for (let i=0;i<row.length;i++) {
          if (i == 1 || i == 2)
          {
            dataString += moment.unix(row[i]).format("DD-MM-YYYY HH:mm:ss", { trim: false }) +";";
          }
          else
          {
            dataString += row[i]+";";
          }
        }
        dataString += '\n';
      }

      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, intervention.title+'_'+resp.title + '_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');

    });
  }

  const downloadUserActivityCSV = (intervention_id) => {
    apiCall({
      action: "get_user_activity_csv",
      token: auth.token,
      data: {
        intervention_id
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = '';
      for (var header of resp.headers) {
        csvContent += header+';';
      }
      csvContent += '\n';
      var dataString = '';
      for (var row of resp.rows) {
        for (let i=0;i<row.length;i++) {
          dataString += row[i]+";";
        }
        dataString += '\n';
      }

      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, intervention.title+'_user_activity_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');

    });
  }

  const downloadWaitingListCSV = () => {
    //api aanroepen
    apiCall({
      action: "get_waitinglist",
      token: auth.token,
      data: {
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = 'id;date_time_create;interestedin;email;\n';
      var dataString = '';
      var scores = '';
      resp.waitinglist.forEach(function(r, index) {

        dataString += r.id +";"+ moment.unix(r.date_time_create).format("DD-MM-YYYY HH:mm:ss", { trim: false }) +";"+ r.interestedin + ";" + r.email+"\n";
      
      });
      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, 'waitinglist_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');
    });
  }

  const downloadGetStartedBol = () => {
    //api aanroepen
    apiCall({
      action: "get_getstarted_bol",
      token: auth.token,
      data: {
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = 'user_id;program;finished;name;email;phone;\n';
      var dataString = '';
      var scores = '';
      resp.rows.forEach(function(r, index) {

        dataString += r.id +";"+ r.program + ";"+ r.finished + ";" +r.name + ";" + r.email+";" + r.phone+"\n";
      
      });
      csvContent += dataString;
      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      var download = function(content, fileName, mimeType) {
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          /// hier loopt die vast maar is misschien ook niet nodig
          //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }

      download(csvContent, 'GetStarted_Bol_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');
    });
  }

   const downloadSleepDiaryCSV = (intervention_id) => {
    
    //api aanroepen
    apiCall({
      action: "get_sleep_diary_csv_rows",
      token: auth.token,
      data: {
        id: intervention_id
      }
    }).then(resp => {

      //setRegistrations(resp.registrations)
        // Building the CSV from the Data two-dimensional array
        // Each column is separated by ";" and new line "\n" for next row
        var csvContent = '';
        for (var header of resp.headers) {
          ////<br> komt mee door contenteditable denk ik
          csvContent += header.replace(/<br\s*[\/]?>/gi, " ")+';';
        }
        csvContent += '\n';
        var dataString = '';
        for (var row of resp.rows) {
          for (let i=0;i<row.length;i++) {
            if (i == 1 || i == 2)
            {
              dataString += moment.unix(row[i]).format("DD-MM-YYYY HH:mm:ss", { trim: false }) +";";
            }
            else
            {
              dataString += row[i]+";";
            }
          }
          dataString += '\n';
        }

        ////<br> komt mee door contenteditable denk ik
        csvContent += dataString.replace(/<br\s*[\/]?>/gi, " ");
        // The download function takes a CSV string, the filename and mimeType as parameters
        // Scroll/look down at the bottom of this snippet to see how download is called
        var download = function(content, fileName, mimeType) {
          var a = document.createElement('a');
          mimeType = mimeType || 'application/octet-stream';

          if (navigator.msSaveBlob) { // IE10
            navigator.msSaveBlob(new Blob([content], {
              type: mimeType
            }), fileName);
          } else if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
              type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            /// hier loopt die vast maar is misschien ook niet nodig
            //location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
          }
        }

        download(csvContent, intervention.title+'_'+resp.title + '_' + moment().format("DD-MM-YYYY HH:mm:ss", { trim: false }) + '.csv', 'text/csv;encoding:utf-8');

      });
  }

  function backToDash(){
    history.push("/")
  }

  return (
    <div className="whiteWrapper data">
      <LeftMenu />
      <div className="container dashboard_container">
      <h3>Exporteer data <span dangerouslySetInnerHTML={{__html: intervention.title}}></span></h3><br />
      <span className='btn btn-primary download' onClick={()=>downloadWaitingListCSV()}>
        {t("Download wachtlijst als csv")} <i className="fas fa-download"></i>
      </span>
      <br />
      <br />
      <span className='btn btn-primary download' onClick={()=>downloadQualtricsRegistrationsCSV()}>
        {t("Download qualtrics registraties als csv")} <i className="fas fa-download"></i>
      </span>
      <br />
      <br />
      <span className='btn btn-primary download' onClick={()=>downloadRegistrationsCSV()}>
        {t("Download registraties als csv")} <i className="fas fa-download"></i>
      </span>
      <br /><br />
      <span className='btn btn-primary download' onClick={()=>downloadUserActivityCSV(intervention.id)}>
        {t("Download useractivity als csv")} <i className="fas fa-download"></i>
      </span>
      <br /><br />
      {
        (parseInt(intervention.id) === 25 || parseInt(intervention.id) === 38) ?
        <>
          <span className='btn btn-primary download' onClick={()=>downloadGetStartedBol()}>
            {t("Download GetStarted Bol.com als csv")} <i className="fas fa-download"></i>
          </span>
          <br /><br />
        </> : <></>
      }
      {
        (parseInt(intervention.id) === 26 || parseInt(intervention.id) === 46 || parseInt(intervention.id) === 47) ?
        <>
          <span className='btn btn-primary download' onClick={()=>downloadSleepDiaryCSV(intervention.id)}>
            {t("Download Sleepdiary data als CSV")} <i className="fas fa-download"></i>
          </span>
          <br /><br />
        </> : <></>
      }
        {
          intervention.settings.questionnaires.map((q, index) => {
            if (typeof q.settings.visible === 'undefined' || (q.settings.visible !== 'undefined') && q.settings.visible !== 'hidden')
            {
              return (
                <div key={index}>
                  <span className='btn btn-primary download' onClick={()=>downloadQuestionnaireCSV(q.id)}>
                    {"Download " +q.title+ " als csv"} <i className="fas fa-download"></i>
                  </span> 
                  <br /><br />
                </div>
              )
            }
          })
        }
      </div>
    </div>
  );
};

export default Data;
