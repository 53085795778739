import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ConfettiFullScreen from "./confettiFullscreen";
import LessonsProgressbar from "./lessons_progressbar";
import OptionalLessons from "./optional_lessons.js";
import { setFinishedCourse, setActivePart, setActiveLesson} from "../../../actions";
import Typewriter from 'typewriter-effect';
import Rewards from "../../rewards";
import doneSVG from '../../../images/course/standard/svg/done.svg';
import lockedSVG from '../../../images/course/standard/svg/locked.svg';
import activeSVG from '../../../images/course/standard/svg/active.svg';
import NotificationBox from '../../alert/notification';
import t from "../../translate";
import { checkNestedProperties } from "../../utils";

const Lessons = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const intervention = useSelector(state => state.intervention);
  const allAnswers = useSelector(state => state.answersLessons);
  const courseFinished = useSelector(state => state.finishedCourse);

  const [lessonList, setLessonList] = useState([]);
  const [illustration, setIllustration] = useState('');
  const [badgesActive, setBadgesActive] = useState(false);
  const [lessonsIntro, setLessonsIntro] = useState('');
  const [notificationOptions, setNotificationOptions] = useState('');
  const [progressBarProgress, setProgressBarProgress] = useState(0);
  const [progressBarPopoverText, setProgressBarPopoverText ] = useState('');

  //tbv flipcard
  const [showCards, setShowCards] = useState([])

  const lessonFinished = (lesson_id) => {
    let currentLessonAnswers = allAnswers.answers.find((answer) => {
      return parseInt(answer.the_id) === parseInt(lesson_id)
    });
    if (typeof currentLessonAnswers !== 'undefined' &&  currentLessonAnswers.hasOwnProperty('finished') && currentLessonAnswers.finished === true)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  const getActiveLessonLink = (lesson_id, index) => {
    if(lessonList[index].status == 'active'){

      let aFinishedLesson = false;
      let anActiveLesson = false;
      for (const lesson of intervention.settings.selfhelp.lessons) {

        if ((lesson.id == lesson_id) || (parseInt(lesson.parent_id) === parseInt(lesson_id) && (!lesson.hasOwnProperty('sub_id') || parseInt(lesson.sub_id) === 0)))
        {
          if (!anActiveLesson && !lessonFinished(lesson.id))
          {
            lesson_id = lesson.id
            break;
          }
        }
      }
      return lesson_id
    } else {
      return lesson_id
    }
  }
  const changeActiveLesson = (lesson_id, index) => {

    //tbv iSleep & BioClock checken of les al is opengesteld
    if (hasAccessToLessons(lesson_id)) {
      /// ga na welke les actief is in de actieve les
      lesson_id = getActiveLessonLink(lesson_id, index)

      dispatch(setActivePart("lesson"));
      dispatch(setActiveLesson(lesson_id));
      history.push("/course/" + intervention.id + "/lesson/" + lesson_id);
    } else {
      setNotificationOptions({
        show: "true",
        text: t("Deze module is toegankelijk vanaf "+getsAccessToLessonsWhen()),
        confirmText: t("Ok")
      })
    }
  }

  const fakeEmptyFunc = () => {
    //om react tevreden te stellen
  }

  useEffect(() => {
    //lessonsIntro
    if(typeof intervention.settings.lessonsIntro !== 'undefined' &&  intervention.settings.lessonsIntro !== '') {
      setLessonsIntro(intervention.settings.lessonsIntro);
    }
    //Badges?
    if(auth.user_id > 0 && typeof intervention.settings.gamification !== "undefined" && intervention.settings.gamification.badges) {
      if(typeof intervention.settings.gamification.badgesFromDateActive !== "undefined" && typeof intervention.settings.gamification.badgesFromDate !== "undefined" && intervention.settings.gamification.badgesFromDateActive) {
        if(typeof intervention.settings.gamification.badgesFromDate[0] !== "undefined" && intervention.settings.gamification.badgesFromDate[0] !== "") {
          //er is een datum vanaf ingesteld voor de gamification, alleen accounts die na deze datum zijn aangemaakt
          //krijgen deze optie te zien.
          if (auth.dtc > intervention.settings.gamification.badgesFromDate[0].substring(0,10)) {
            setBadgesActive(true);
          }
        }
      } else {
        setBadgesActive(true);
      }
    }
  }, [auth, intervention]);

  //actieve les bepalen -> les waar nog onafgeronde lessen in zitten
  useEffect(() => {

    let newlessonList = [];
    let unregisteredLesson = {
      id: 0,
      title: '',
      status: 'closed'
    };
    let lastLessonStatus = 'finished';
    let allFinished = true;
    let someFinished = false;
    
    //popuptext tbv evt progressbar setten:
    if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && intervention.settings.selfhelp.lessons.length > 0 && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_0'] !== 'undefined') {
      setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_0']);
    }
    //tbv progress:
    let totalLessons = 0;
    let finishedLessons = 0;

    for (const lesson of intervention.settings.selfhelp.lessons) {
      totalLessons++;

      if (parseInt(lesson.parent_id) === 0 && parseInt(lesson.sub_id) === 0) //les op hoofdniveau
      {
        if (unregisteredLesson.title.length > 0)
        {
          if (allFinished) {
            unregisteredLesson.status = 'finished';
            //popuptext tbv evt progressbar setten:
            if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id] !== 'undefined') {
              setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id]);
            }
          } else {
            if (someFinished) {
              unregisteredLesson.status = 'active';
            } else {
              unregisteredLesson.status = 'closed';
            }
          }
          if (unregisteredLesson.status === 'closed' && lastLessonStatus === 'finished')
          {
            unregisteredLesson.status = 'active';
          }
          lastLessonStatus = unregisteredLesson.status;
          newlessonList.push(unregisteredLesson);
          if (unregisteredLesson.status === 'active') {
            let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
              return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
            })
            setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
          }
        } else {
          //tbv de 1e module...
          //popuptext tbv evt progressbar setten:
          //Maar alleen als er een start is gemaakt met module 1
          let lessonAnswers = allAnswers.answers.find((answer) => {
            return parseInt(answer.the_id) === parseInt(lesson.id)
          });
          if (typeof lessonAnswers !== 'undefined' && lessonAnswers.hasOwnProperty('finished') && lessonAnswers.finished) {
            if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id] !== 'undefined') {
              setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_' + lesson.id]);
            }
          }
        }
        let alternative_menu_image = '';
        if (typeof lesson.alternative_menu_image !== "undefined")
        {
          alternative_menu_image = lesson.alternative_menu_image;
        }
        unregisteredLesson = {
          id: lesson.id,
          title: lesson.title,
          alternative_menu_image,
          status: 'closed'
        };
        allFinished = true;
        someFinished = false;
      }

      //bepalen of les is afgerond...
      let lessonAnswers = allAnswers.answers.find((answer) => {
        return parseInt(answer.the_id) === parseInt(lesson.id)
      });
      if (typeof lessonAnswers === 'undefined' || !lessonAnswers.hasOwnProperty('finished') || lessonAnswers.finished === false)
      {
        allFinished = false;
      }
      else
      {
        someFinished = true;
        finishedLessons++;
      }
    }

    setProgressBarProgress(finishedLessons / totalLessons * 100);

    if (allFinished)
    {
      unregisteredLesson.status = 'finished';

      //programma afgerond:
      if (typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar && intervention.settings.selfhelp.lessons.length > 0 && typeof intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_00'] !== 'undefined') {
        setProgressBarPopoverText(intervention.settings.selfhelp.progressbar_popover_text['progressbar_popover_00']);
      }

    }
    else
    {
      if (someFinished)
      {
        unregisteredLesson.status = 'active';
      }
      else
      {
        unregisteredLesson.status = 'closed';
      }
    }
    if (unregisteredLesson.status === 'closed' && lastLessonStatus === 'finished')
    {
      unregisteredLesson.status = 'active';
    }
    newlessonList.push(unregisteredLesson)
    if (unregisteredLesson.status === 'active') {
      let activeLesson = intervention.settings.selfhelp.lessons.find(lesson => {
        return parseInt(lesson.id) === parseInt(unregisteredLesson.id)
      })
      setIllustration(typeof activeLesson.settings.image != 'undefined' ? activeLesson.settings.image :'')
    }

    if(allFinished){ /// find last lesson image and set image of that lesson
      for(let i = intervention.settings.selfhelp.lessons.length - 1 ; i >= 0 ; i-- ){
        if(intervention.settings.selfhelp.lessons[i].parent_id == 0){
          setIllustration(typeof intervention.settings.selfhelp.lessons[i].settings.image != 'undefined' ? intervention.settings.selfhelp.lessons[i].settings.image :'')
          break;
        }
      }

    }
    //opschonen -> tbv bug dat niet iedere les altijd op finished wordt gezet en er dan meerdere lessen actief zijn...
    let foundactive = false;
    for (var i = newlessonList.length -1;i>=0;i--) {
      if (newlessonList[i].status === 'active')
      {
        if (!foundactive)
        {
          foundactive = true;
        }
        else
        {
          newlessonList[i].status = 'finished';
        }
      }
    }
    setLessonList(newlessonList);

    if(courseFinished) {
      setEndCourse('true');
      dispatch(setFinishedCourse(false));

      /// call maken geen andere functie die einde markeert volgens mij
      // => jawel, in end_lesson in de api
      /* apiCall({
        action: "add_points",
        token: getCookie("token"),
        data:{
          intervention_id:intervention.id,
          type:"finish_intervention",
        }
      }).then(resp => {

      }); */
    }

  }, [intervention, allAnswers]);

  const [endCourse, setEndCourse] = useState(false)

  const hasAccessToLessons = (lesson_id) => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen en modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26 && parseInt(lesson_id) !== 1278) { //alleen testen voor interventie 26, anders true
      if (typeof allAnswers.answers !== 'undefined') { //zijn er antwoorden, anders false
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') { //startTimestamp al gezet?
          var today = new Date();
          var sixdaysago = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
          //var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60*24*6);
          var sixDaysAgoTimestamp = (sixdaysago.getTime()/1000) - (60*60);
          if (allAnswers.answers[lessonIndex].finished && allAnswers.answers[lessonIndex].startTimestamp < sixDaysAgoTimestamp) {
            return true;
          }
        }
      } 
      return false;
    } */
    return true;
  }

  const getsAccessToLessonsWhen = () => {
    //Deelnemers van iSleep & BioClock krijgen pas na 6 dagen toegang tot de oefeningen (tools) en de modules (behalve intro)
    /* if (typeof intervention.id !== 'undefined' && parseInt(intervention.id) === 26) {
      if (typeof allAnswers.answers !== 'undefined') {
        //answer item voor lesson met id 1282 opzoeken
        let lessonIndex = allAnswers.answers.findIndex((element) => {
          return parseInt(element.the_id) === 1282
        })
        if (typeof lessonIndex !== 'undefined' && lessonIndex > -1 && typeof allAnswers.answers[lessonIndex].startTimestamp !== 'undefined') {
          //var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60*24*6)) * 1000);
          var date = new Date((parseInt(allAnswers.answers[lessonIndex].startTimestamp) + (60*60)) * 1000);
          return date.getDate()+"-"+(date.getMonth() + 1)+"-"+date.getFullYear()+" "+date.getHours()+":"+(date.getMinutes()<10?'0':'') + date.getMinutes();
        }
      }
    } */
    return ''
  }

  const goBaseline = () => {
    dispatch(setActivePart("baseline"));
    history.push("/baseline/");
  }

  function flipCard(index){
    let showCardsNew = [...showCards]
    if(showCardsNew.includes(index)){
      let indexOfIndex = showCardsNew.indexOf(index);
      if (indexOfIndex !== -1) showCardsNew.splice(indexOfIndex, 1);
    } else {
      showCardsNew.push(index)
    }
    setShowCards(showCardsNew)
  }

  return (
    <>
      
      <div className='lessons'>
        <div className="welkom">{t("Welkom bij")}</div>
        <div className="title"><span dangerouslySetInnerHTML={{__html: intervention.title}} /></div>
        {/* {typeof intervention.settings.subtitle !== "undefined" && intervention.settings.subtitle !== "" ?
          <div className="title">
            <h1 id="typed_1" className="subTitle"><Typewriter options={{delay:40}}
            onInit={(typewriter) => {
              typewriter.typeString(intervention.settings.subtitle)
                .callFunction(() => {
                  document.getElementById("typed_1").className = "finished subTitle"
                })
                //.pauseFor(2500)
                //.deleteAll()
                .start();
            }}
            /></h1>
            <h1 className="dummy">{intervention.settings.subtitle} oho</h1>
          </div>
        :''} */}

        {
          typeof intervention !== "undefined" && typeof intervention.settings !== "undefined" && typeof intervention.settings.coverPhoto !== "undefined" && intervention.settings.coverPhoto.length > 0 ?
            <div className="illustration">
              <img src={intervention.settings.coverPhoto} />
            </div>
          :
            ''
        }
        <div className="lessonsHolder">
          {
            typeof intervention.settings.selfhelp.include_progressbar !== 'undefined' && intervention.settings.selfhelp.include_progressbar ?
            <>
              <LessonsProgressbar progress={progressBarProgress} popoverText={progressBarPopoverText} />
            </> : <></>
          }
          {
            auth.doT0 ? 
            <div className="items" style={{marginTop: '40px', background: 'none'}} onClick={()=>goBaseline()} >
              <div className="item active">
                  <span style={{paddingLeft: '20px !important'}}>{t("Start hier: Waar sta je nu?")}</span>
              </div> 
            </div> : <></>
          }
          <div className="intro">
            <h2>{typeof intervention.settings.menu != "undefined" ? intervention.settings.menu.modules:''}</h2>
            <span dangerouslySetInnerHTML={{__html: lessonsIntro}}></span>
          </div>
          <div className="items_alt">
          {

            lessonList.map((lesson, index) => {
              if (auth.doT0) {
                lesson.status = 'closed'
              }
              if (lesson.title.length > 8 && lesson.title.substr(0, 9).toLowerCase() === '[heading]') {
                return false;
              } else {
                if (!checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcard') || intervention.settings.selfhelp.alternative_menu_flipcard === false) {
                  return (
                  <div key={index} className={'pointer item_alt '+lesson.status} onClick={(lesson.status === 'finished' || lesson.status === 'active') ? ()=>changeActiveLesson(lesson.id, index) : ()=>fakeEmptyFunc()} style={lesson.status === 'active' ? {border: '1px solid #000'} : lesson.status === 'closed' ? {cursor: 'not-allowed'} : {}}>
                    <div className={'title '+lesson.status}>{lesson.title}</div>
                    <div className={'image '+lesson.status} style={{backgroundImage: (typeof lesson.alternative_menu_image !== "undefined" && lesson.alternative_menu_image.length > 0) ? 'url("'+lesson.alternative_menu_image+'")' : ''}}></div>
                      <div className='activated'>
                        <div className="vinkseq">
                          {
                            lesson.status === 'active' ? <img src={activeSVG} />
                              :
                            <> { lesson.status === 'closed' ? <img src={lockedSVG} /> : <img src={doneSVG}/> } </>
                          }
                        </div>
                      </div>
                  </div>)
                } else {
                  return(
                    <div className={"flip-card pointer " + (showCards.includes(lesson.id) ? 'back':'front')} onClick={() => flipCard(lesson.id)}>
                      <div className="inner">
                        <div className="front" style={lesson.status === 'active' ? {border: '1px solid #e76d71'} : {}}>
                          <div className={'title '+lesson.status}>{lesson.title}</div>
                          <div className={'image '+lesson.status} style={{backgroundImage: (typeof lesson.alternative_menu_image !== "undefined" && lesson.alternative_menu_image.length > 0) ? 'url("'+lesson.alternative_menu_image+'")' : ''}}></div>
                          <div className='activated'>
                            <div className="vinkseq">
                              {
                                lesson.status === 'active' ? <img src={activeSVG} /> : 
                                  <>
                                    {
                                    lesson.status === 'closed' ? <img src={lockedSVG} /> : <img src={doneSVG}/>
                                    }
                                  </>
                              }
                            </div>
                          </div>
                      </div>
                        <div className="back" style={lesson.status === 'active' ? {border: '1px solid #e76d71'} : {}}>
                          <div className="flip-card-text">{checkNestedProperties(intervention, 'settings', 'selfhelp', 'alternative_menu_flipcardtexts', lesson.id) ? intervention.settings.selfhelp.alternative_menu_flipcardtexts[lesson.id] : ''}</div>
                          <button className="btn btn-primary" onClick={(lesson.status === 'finished' || lesson.status === 'active') ? ()=>changeActiveLesson(lesson.id, index) : ()=>fakeEmptyFunc()} >{t("Start module")}</button>
                        </div>
                      </div>
                      <div className='activated'>
                        <div className="vinkseq">
                          {
                            lesson.status === 'active' ? <img src={activeSVG} />
                              :
                            <> { lesson.status === 'closed' ? <img src={lockedSVG} /> : <img src={doneSVG}/> } </>
                          }
                        </div>
                      </div>  
                    </div>)
                }
              }
            })
          }
          </div>
        </div>
        {endCourse  ?
          <ConfettiFullScreen setEndCourse={setEndCourse}/>
          : <></>
          /* badgesActive ? <Rewards /> : <></> */
        }
        <OptionalLessons lessonList={lessonList}/>
        
        <NotificationBox options={notificationOptions} setNotificationOptions={setNotificationOptions} />
      </div>
    </>
  )
}

export default Lessons;
