import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import t from '../../translate';
import Universities from "./universities.js";
import { validateEmail } from '../../utils';
import apiCall from "../../api";
import { interventionObject } from '../../registrationtexts/registrationTextObjects';
import flag_en from '../../../images/language-switch-flag-en.png';
import flag_nl from '../../../images/language-switch-flag-nl.png';

const Step2 = ({ registrationTexts, changeLanguage, registrationData, setRegistrationData, setLandingPage }) => {

  const history = useHistory()

  const [interventionTexts, setInterventionTexts] = useState(interventionObject)
  const [languageCode, setLanguageCode] = useState('nl')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [addedToWaitingList, setAddedToWaitingList] = useState(false)

  useEffect(() => {
    let index = registrationTexts.panel2.interventions.findIndex(item => parseInt(item.id) === parseInt(registrationData.intervention_id))
    if (index > -1) {
      setInterventionTexts(registrationTexts.panel2.interventions[index])
    }
  }, [registrationData.intervention_id])

  //registrationTextsbject gebruikt eng ipv en als engelse code, daarom deze niet zo fraaie workarround
  useEffect(() => {
    if (registrationData.language_id === 1) {
      setLanguageCode('nl')
    } else {
      setLanguageCode('eng')
    }
  }, [registrationData.language_id])

  function setStep(step) {
    if (step === 1) {
      setLandingPage('')
      history.push('/registration/')      
    }
    let registrationDataTemp = JSON.parse(JSON.stringify(registrationData))
    registrationDataTemp.step = step
    setRegistrationData(registrationDataTemp)
    window.scrollTo(0,0)
  }

  function addToWaitinglist() {

    //unset error message
    setErrorMessage('')

    //check if email is valid
    if (!validateEmail(email)) {
      setErrorMessage(t("Vul een geldig e-mailadres in"))
      return
    }

    //verzend email naar api
    apiCall({
      action: "add_waitinglist",
      data: {
        intervention_id: registrationData.intervention_id,
        email
      }
    }).then(resp => {
      if (resp.addWaitinglist) {
        setAddedToWaitingList(true)
      } else {
        setErrorMessage(resp.msg)
      }
    })

  }

  return (
    <>
      <div className="step2">
      <header>
        <button type="button" className="btn prev" onClick={() => setStep(1)}>{t("Terug naar alle programma's")}</button>
        <a className="btn login" href="/">{t("Log in")}</a>
        <div className="languageswitchheader" onClick={() => changeLanguage(parseInt(registrationData.language_id) === 1 ? 2 : 1)}>
            <div className={parseInt(registrationData.language_id) === 1 ? 'code active' : 'code pointer'}> NL <img src={flag_nl} style={{height: '20px'}} /> </div>
            <div className={parseInt(registrationData.language_id) === 2 ? 'code active' : 'code pointer'}> EN <img src={flag_en} style={{height: '20px'}} /> </div>
        </div>         
      </header>

        <div className="container">
        
            <h1>{interventionTexts.title[languageCode]}</h1>
            <div className="intervention-description" dangerouslySetInnerHTML={{__html: interventionTexts.description[languageCode]}} />
            <div className="intervention-image"><img src={registrationData.interventionImage} /></div>
            <br /><br />
            {
              registrationData.coaches_available ?
                <button type="button" className="btn btn-primary next" onClick={() => setStep(3)}>{interventionTexts.button[languageCode]}</button>
                :
                
                !addedToWaitingList ?
                  <>
                    <strong style={{fontWeight: '700'}} dangerouslySetInnerHTML={{__html: interventionTexts.waitinglist[languageCode]}}></strong>
                    <br />
                    {
                      errorMessage.length > 0 ?
                        <div className="alert alert-danger" role="alert">
                          {errorMessage}
                        </div>
                        : <></>
                    }
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      aria-describedby="email"
                      placeholder={t("jouw e-mailadres")}
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                      autoCorrect="off" 
                      autoCapitalize="none"
                      style={{width: '100%', maxWidth: '400px'}}
                    />
                    <br />
                    <button type="button" className="btn btn-primary next" onClick={() => addToWaitinglist()}>{t("Aanmelden voor wachtlijst")}</button>
                  </>
                  :
                  <>
                    <strong style={{fontWeight: '700'}}>{t("Je bent toegevoegd aan de wachtlijst. Zodra er ruimte is laten we je dat weten.")}</strong>
                  </>

            }

            <br /><br /><br /><br />
            <h2>{interventionTexts.studentsReviewsTitle[languageCode]}</h2>
            <div className="student-reviews">
              
              {
                interventionTexts.studentsReviewsContent.map((review, key) => {
                  return(

                      <div key={key} className="student-review" dangerouslySetInnerHTML={{__html: review[languageCode]}}></div>

                  )
                })
              }
            </div>
            {
              interventionTexts.mediaContent.length > 0 ?
                <>
                  <br /><br />
                  <h2>{interventionTexts.mediaTitle[languageCode]}</h2>
                  <div>
                  {
                    interventionTexts.mediaContent.map((item, key) => {
                      if (item.type === 'youtube') {
                        return (
                          <div className="youtube-container" key={key} style={{marginBottom: '40px'}} >
                            <iframe src={'https://www.youtube.com/embed/' + item[languageCode]} title="YouTube video player" frameBorder="0"  allowFullScreen></iframe>
                          </div>
                        )
                      } else {
                        //image
                        return (
                         <img src={item[languageCode]} key={key} className="step2Image" />
                        )
                      }
                    })
                  }
                  </div>

                </> : <></>
            }
            
            <Universities step={registrationData.step} registrationTexts={registrationTexts} languageCode={languageCode} />


        </div>
      </div>
    {/* <Footer language={registrationData.language_id} changeLanguage={changeLanguage} /> */}
    </>
  )

}

export default Step2;
