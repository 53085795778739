import { text_14 } from "./14.js";
import { text_24 } from "./24.js";
import { text_25 } from "./25.js";
import { text_26 } from "./26.js";
import { text_30 } from "./30.js";
import { text_31 } from "./31.js";
import { text_39 } from "./39.js";
import { text_40 } from "./40.js";
import { text_41 } from "./41.js";
import { text_43 } from "./43.js";
import { text_45 } from "./45.js";
import { text_47 } from "./47.js";

import parse from 'html-react-parser';

const text = [text_14, text_24, text_25, text_26, text_30, text_31, text_39, text_40, text_41, text_43, text_45, text_47];

export {text}

export const getText = (id, field) => {
  let text_obj = text.filter(function (intervention_text) {
    return intervention_text.intervention == id
  });

  if(text_obj.length > 0 && typeof text_obj[0][field] !== "undefined") {
    return parse(text_obj[0][field]);
  } else {
    return '';
  }

}
